/* global __WATCH__:false */
import { triggerTracking } from 'modules/ensighten_tracking/EnsightenTracking';
import { getLogOptions } from 'constants/logging';
import { functionalCookiesIsEnabled } from 'utils/onetrust';

export default class YoutubePlayer {
  constructor({ id, tracking_name = 'nvs', domElementId, autoplay = false }) {
    this.domElementId = domElementId;
    this.id = id;
    this.tracking_name = tracking_name;
    this.autoplay = autoplay ? 1 : 0;
    this.player = null;
    this.log = getLogOptions({ id: 'ytPlayer' });

    this.onBindedOnReady = this.onPlayerReady.bind(this);
    this.onBindedOnStateChange = this.onPlayerStateChange.bind(this);
    this.onBindedOnError = this.onPlayerError.bind(this);
    this.init();
  }

  init() {
    // add youtube api to dom - async
    const yt = '//www.youtube-nocookie.com'; // '//www.youtube-nocookie.com'; // '//www.youtube.com';
    const tag = document.createElement('script');
    tag.src = `//www.youtube.com/iframe_api`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const el = document.getElementById(this.domElementId);
    const origin = el.dataset.origin;
    const opts =
      'enablejsapi=1' +
      '&controls=2' +
      '&color=white' +
      '&autohide=2' +
      '&modestbranding=1' +
      '&wmode=opaque' +
      '&showinfo=0' +
      '&rel=0' +
      `&mute=${this.autoplay}` +
      `&autoplay=${this.autoplay}` +
      `&origin=${origin}`;
    const src = `${yt}/embed/${this.id}?${opts}`;

    const fc = functionalCookiesIsEnabled();
    if (fc) {
      el.src = src;
    } else {
      el.dataset.src = src;
    }
    el.classList.add('optanon-category-fc');
    this.log.enabled &&
      console.log(`${this.log.prep} YoutubePlayer.init | id ${this.id}`, this.log.clr);
  }

  onPlayerReady(event) {
    this.log.enabled && console.log(`${this.log.prep} onPlayerReady`, this.log.clr, this.player);
  }

  onPlayerStateChange(event) {
    /**
     * -1 (unstarted)
     * 0 (ended)
     * 1 (playing)
     * 2 (paused)
     * 3 (buffering)
     * 5 (video cued).
     */
    switch (event.data) {
      case 1:
        this.trackInteraction('play');
        break;
      case 2:
        this.trackInteraction('pause');
        break;
    }
    this.log.enabled && console.log(`${this.log.prep} onPlayerStateChange`, this.log.clr, event);
  }

  onPlayerError(event) {
    console.error(`onPlayerError`, event);
  }

  setupPlayer() {
    if (this.player) {
      this.log.enabled &&
        console.log(`${this.log.prep} setupPlayer : player exists`, this.log.clr, this.player);
    } else {
      this.player = new YT.Player(this.domElementId, {
        playerVars: { autoplay: this.autoplay },
        events: {
          onReady: this.onBindedOnReady,
          onStateChange: this.onBindedOnStateChange,
          onError: this.onBindedOnError,
        },
      });
      this.log.enabled && console.log(`${this.log.prep} setupPlayer`, this.log.clr, this.player);
    }
  }

  playVideo({ id, tracking_name = 'nvs', autoplay = false }) {
    this.id = id;
    this.tracking_name = tracking_name;
    this.autoplay = autoplay;
    if (this.player && this.player.loadVideoById) {
      this.log.enabled && console.log(`${this.log.prep} playVideo ${id}`, this.log.clr);
      this.player.loadVideoById(id, 0);
    } else {
      this.log.enabled &&
        console.log(`${this.log.prep} playVideo ${id} : player missing`, this.log.clr);
    }
    this.trackInteraction('click');
  }

  stopVideo() {
    this.player && this.player.stopVideo && this.player.stopVideo();
  }

  trackInteraction(type) {
    triggerTracking('interaction-video', {
      englishname: 'nvs', // todo : add englishname
      videotitle: this.tracking_name,
      videocategory: 'nvs',
      featuredcontent: this.tracking_name,
      interaction: `video content ${type}`,
      interactionevent: 1,
      contentclickevent: 1,
      videoclickedevent: 1,
    });
  }
}
