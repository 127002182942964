const { getLogOptions } = require('constants/logging');
let VideoPageController = function (options) {
  this.log = getLogOptions({ id: 'video' });
  this.dynamic_url = options.dynamic_url || '';
  this.static_url = options.static_url || '';
  this.locale = options.locale || '';
  this.friendly_url = options.friendly_url || '';
  this.data = options.data || {};
  // this.videos = options.data.videos || null;
  this.filterOptions = [];
  this.renderData = [];
  this.videoPageTemplate = options.videoPageTemplate || HandlebarsTemplates['videoPage'];
  this.container = options.container || Spdy('.inline_video_container');
  this.onInit = options.onInit || function () {};
  this.videos = options.data.videos.reduce((acc, obj) => {
    const video = obj?.video;
    if (video && video.data) {
      const { data = {}, avs_id, name } = video;
      data.name = name;
      const { type, youtube_id } = data;
      let videoId;
      if (type === 'youtube' && youtube_id) {
        videoId = youtube_id;
      } else if (type === 'avs' && avs_id) {
        videoId = avs_id;
      }
      data.video_id = videoId;
      acc.push(data);
    }
    return acc;
  }, []);

  if (this.videos != null) {
    this.videos.reverse();
    this.init();
  }
  return this;
};

VideoPageController.prototype = {
  init: function () {
    this.filterOptions.push(this.data['all_label']);
    for (let i in this.videos) {
      if (this.filterOptions.indexOf(this.videos[i].show_title) == -1) {
        this.filterOptions.push(this.videos[i].show_title);
      }
    }
    this.current_selected_title = this.filterOptions[0];
    this.sort(this.data['all_label']); //load all videos initially

    this.onInit();
    return this;
  },

  create: function () {
    this.filterMenu = Spdy('.select_menu');
    this.filterButtons = this.filterMenu.find('[data-filter]');
    let t = this;
    this.filterButtons.on('click', function (e) {
      Spdy.preventDefault(e);
      t.onFilterButtonClick(this);
    });
    this.filterMenu.on('click', () => {
      this.filterMenu.toggleClass('active');
    });

    TURNERENTS.destroyInlineVideoButtonClick();
    TURNERENTS.createInlineVideoButtonClick();
  },

  render: function () {
    this.destroy();
    if (this.container.length > 0) {
      this.container.html(
        this.videoPageTemplate({
          dynamic_url: this.dynamic_url,
          static_url: this.static_url,
          friendly_url: this.friendly_url,
          renderData: this.renderData,
          filterOptions: this.filterOptions,
          select_show: this.data.select_show,
          current_selected_title: this.current_selected_title,
        })
      );
      this.create();
    }
  },

  sort: function (_term) {
    this.log.enabled && console.log(`${this.log.prep} sort`, this.log.clr, _term);
    if (_term == this.data['all_label']) {
      this.renderData = this.videos.slice(0);
    } else {
      this.renderData = [];
      for (let i in this.videos) {
        if (this.videos[i].show_title == _term) {
          this.renderData.push(this.videos[i]);
        }
      }
    }
    this.render();
  },

  onFilterButtonClick: function (item) {
    Spdy('[data-filter]').removeClass('active');
    Spdy('[data-filter="' + Spdy(item).data('filter') + '"]').addClass('active');
    this.current_selected_title = Spdy(item).data('filter');
    this.sort(this.current_selected_title);
  },

  destroy: function () {
    let t = this;
    try {
      this.filterMenu.off('click');
    } catch (e) {}
    try {
      this.filterButtons.off('click');
    } catch (e) {}
  },
};

function createVideoPageController(options) {
  return new VideoPageController(options);
}

module.exports = createVideoPageController;
