import { triggerTracking } from 'modules/ensighten_tracking/EnsightenTracking';

const moment = require('moment');
const createEllipsity = require('../utils/ellipsity');
let FeaturesLandingController = function (options) {
  let i;
  this.option_ALL = options.filtersGenreKeys.all || 'all';
  this.option_UNCATEGORISED = options.filtersGenreKeys.uncategorised || 'uncategorised';
  this.unparsed_data = options.data;
  this.data = [];
  /**
     * Organising data like this
     * films : { byID: {film001:{...}, film002:{...}, allIDs: ['film001', 'film002']}
     *
     * Genre lookup
     * genres: { byID: {
     *          all:{
     *              id:'all',
     *              items:[film001, film002]
     *              },
     *          uncategorised:{
     *              id:'uncategorised',
     *              items:[film001, film002]
     *              }

     */
  let film, genre;
  this.films = { byID: {}, allIDs: [] };
  for (i in this.unparsed_data) {
    film = this.unparsed_data[i];
    // Mutations
    film.data.genre = film.data.genre ? film.data.genre.toLowerCase() : this.option_UNCATEGORISED;
    film.data.parsed_day_of_broadcast = this.parseDateAndTime(
      film.data.day_of_broadcast,
      film.data.time_of_broadcast
    );
    film.data.video_active = film.data.video_active === '1' ? true : false;
    if (film.data.video_active) {
      film.data.video_id = film.data['video_' + film.data.video_type + '_id'];
    }
    // Sort
    this.films.byID[film.id] = film;
    this.films.allIDs.push(film.id);
  }

  this.genres = { byID: { all: [] }, allIDs: [this.option_ALL] };
  for (i in this.unparsed_data) {
    film = this.unparsed_data[i];
    genre = film.data.genre;
    if (this.genres.allIDs.indexOf(genre) === -1) {
      this.genres.allIDs.push(genre);
      this.genres.byID[genre] = [];
    }
    this.genres.byID[genre].push(film.id);
    this.genres.byID.all.push(film.id);
  }
  // console.log('films', this.films);
  // console.log('genres', this.genres);

  this.filters = options.filters;
  this.locale = options.locale;
  this.friendly_url = options.friendly_url;
  this.static_url = options.static_url;
  this.dynamic_url = options.dynamic_url;
  this.target_container = Spdy('.feature_landing_target');
  this.feature_template = options.feature_template || HandlebarsTemplates['FeatureLanding'];
  this.filter_buttons = Spdy('.filter_button');
  this.filter_menu = Spdy('.tabs');
  this.selected_options = [this.option_ALL]; // set it to default to sorting 'all' when first clicked

  this.pagination_count = 0;
  this.PAGINATION_TOTAL = 21;
  this.showMoreButton = Spdy('.show_more');
  this.showMoreButton.hide();
  this.showMoreButton.on('click', this.handleShowMore.bind(this));
  this.activeFilter = '';
  this.init();
  return this;
};

FeaturesLandingController.prototype = {
  init: function () {
    let filter = 'priority';
    if (this.filter_buttons.elements[0]) {
      filter = Spdy(this.filter_buttons.elements[0]).data('filter');
    }
    this.sort(filter);
    this.createFilterButtons();
    return this;
  },

  handleShowMore: function () {
    if (this.paginatedData) {
      this.pagination_count++;
      this.render(this.paginatedData);
    }
  },

  render: function (data) {
    if (data.length > this.PAGINATION_TOTAL) {
      this.paginatedData = data;
      this.showMoreButton.show();
      let total = this.pagination_count * this.PAGINATION_TOTAL;
      let pageData = data.slice(0, total);
      this.renderPage(pageData);
      if (total > data.length) {
        this.showMoreButton.hide();
      }
    } else {
      this.showMoreButton.hide();
      this.paginatedData = null;
      this.renderPage(data);
    }
  },

  renderPage: function (items) {
    // console.log('renderPage', this.pagination_count, items);
    this.deActivateOverlayToggles();
    this.deActivateExternalFeatureClick();
    TURNERENTS.destroyVideoButtonClick();
    for (const obj of items) {
      const { data } = obj;
      let { video_active, video, title_text } = data;
      if (!video) {
        video_active = false; // https://jira.jollywise.co.uk/browse/TURNERENTS-486?focusedCommentId=176487&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-176487
      }

      if (video_active) {
        const { data: videoData } = video;
        const { type = 'youtube', title = '', thumbnail = '', text = '' } = videoData;
        let id = null;
        if (type === 'youtube' && videoData.youtube_id) {
          id = videoData.youtube_id;
        } else if (type === 'avs' && video.avs_id) {
          id = video.avs_id;
        }
        if (id) {
          const videoData = {};
          videoData.id = id;
          videoData.type = type;
          videoData.name = title;
          videoData.thumbnail = thumbnail;
          videoData.text = text.length > 100 ? '' : text;

          data.videoData = videoData;
        } else {
          // no id found so disable the video!
          console.warn(`Disabled ${title_text} video. No avs | youtube video id found.`);
          data.video_active = false;
        }
      }

      data.overlay_active = true;
      data.is_redirect = false;
      if (obj.type === 'external') {
        // TURNERENTS-526 : new redirect feature type
        data.overlay_active = false;
        data.is_redirect = true;
        if (data.redirect_target === 'new') {
          data.redirect_target = '_blank';
        } else {
          data.redirect_target = '_' + data.redirect_target;
        }
      }
    }

    this.target_container.html(
      this.feature_template({
        dynamic_url: this.dynamic_url,
        static_url: this.static_url,
        friendly_url: this.friendly_url,
        data: items,
        image_not_found: TURNERENTS.ImageNotFoundV,
      })
    );
    this.activateOverlayToggles();
    this.activateExternalFeatureClick();
    TURNERENTS.createVideoButtonClick();

    // ellipsify the titles - using https://github.com/Xela101/Ellipsity for speed
    let overlays = document.querySelectorAll('.name');
    overlays.forEach(function (item) {
      createEllipsity().ellipsify(item);
    });
  },

  getAllFilms: function () {
    let _byID = this.films.byID;
    return this.films.allIDs.reduce(function (acc, val) {
      acc.push(_byID[val]);
      return acc;
    }, []);
  },

  parseDateAndTime: function (dateString, timeString) {
    if (!dateString) {
      return moment();
    }
    let strict = true;
    let ret = '';
    let dateandtimeString = '';
    if (moment(dateString, 'YYYY-MM-DDTHH:mm:ss', strict).isValid()) {
      /**
       * Add time
       */
      dateandtimeString = dateString.split('T')[0] + 'T' + timeString;
      ret = moment(dateandtimeString, 'YYYY-MM-DDTHH:mm:ss').toDate();
    } else if (moment(dateString, 'DD/MM/YYYY', strict).isValid()) {
      dateandtimeString = dateString + 'T' + timeString;
      ret = moment(dateandtimeString, 'DD/MM/YYYYTHH:mm').toDate();
    } else {
      ret = moment(); // just return now
      console.warn('>> parseDate', dateString, ' appears to be invalid date format, using now');
    }
    return ret;
  },

  sort: function (filter, options) {
    // console.log('sorting by', filter, '| activeFilter', this.activeFilter, '| options', options);
    if (this.activeFilter !== filter || filter === 'genre') {
      let data = this.getAllFilms();
      if (filter === 'genre' && this.activeFilter !== 'genre') {
        this.pagination_count = 1;
      } else if (filter !== 'genre') {
        this.pagination_count = 1;
      }
      this.activeFilter = filter;
      switch (filter) {
        case 'priority':
          // priority key in film object auto sorts
          this.render(data);
          break;
        case 'name':
          this.sortAlphabetical(data);
          this.render(data);
          break;
        case 'featured':
          data = this.filterFeatured(data);
          this.render(data);
          break;
        case 'genre':
          data = this.filterGenre(options);
          this.render(data);
          break;
        case 'air_date':
          this.sortAirDate(data);
          this.render(data);
          break;
        default:
          this.render(data);
          break;
      }
    }
  },

  sortAlphabetical: function (array) {
    array.sort(function (a, b) {
      let nameA = a.data.title_text.toLowerCase(),
        nameB = b.data.title_text.toLowerCase();
      if (nameA < nameB) {
        //sort string ascending
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });
  },

  filterGenre: function (genres) {
    let _genresByID = this.genres.byID;
    let genreFilmsByID = genres.reduce(function (acc, val) {
      return acc.concat(_genresByID[val]);
    }, []);
    let _byID = this.films.byID;
    let genreFilms = genreFilmsByID.reduce(function (acc, val) {
      acc.push(_byID[val]);
      return acc;
    }, []);
    return genreFilms.sort(function (a, b) {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    });
  },

  sortAirDate: function (array) {
    let dateA, dateB;
    array.sort(function (a, b) {
      dateA = a.data.parsed_day_of_broadcast;
      dateB = b.data.parsed_day_of_broadcast;
      if (dateA < dateB) {
        //sort string ascending
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0; //default return value (no sorting)
    });
  },

  filterFeatured: function (array) {
    return array.reduce(function (acc, val) {
      let featured = val.data.featured === '1' ? true : false;
      if (featured) {
        acc.push(val);
      }
      return acc;
    }, []);
  },

  resetGenres: function () {
    let genre_menu = Spdy('#genre_select').find('.genre_option');
    genre_menu.each(function (index, item) {
      Spdy(item).removeClass('selectedOption');
    });
  },

  handleGenreClick: function (e) {
    let genreElement = e.currentTarget;
    let genre = Spdy(genreElement);
    let genreType = genre.data('genre-value');
    if (genreType === this.option_ALL) {
      this.resetGenres();
      genre.toggleClass('selectedOption');
      this.selected_options = [this.option_ALL];
    } else {
      if (this.selected_options.indexOf(this.option_ALL) !== -1) {
        // remove all option
        this.selected_options.splice(this.selected_options.indexOf(this.option_ALL), 1);
        document.querySelectorAll('[data-genre-value="all"]')[0].classList.remove('selectedOption');
      }
      genre.toggleClass('selectedOption');
      // toggle options on/off
      if (this.selected_options.indexOf(genreType) === -1) {
        this.selected_options.push(genreType);
      } else {
        this.selected_options.splice(this.selected_options.indexOf(genreType), 1);
      }
      // if options empty, add all
      if (this.selected_options.length === 0) {
        this.selected_options = [this.option_ALL];
        document.querySelectorAll('[data-genre-value="all"]')[0].classList.add('selectedOption');
      }
    }
    this.sort('genre', this.selected_options);
    // console.log('handleGenreClick', genreType, this.selected_options);
  },

  handleFilterClick: function (e) {
    let filterElement = e.currentTarget;
    let filter = Spdy(filterElement);
    let filterType = filter.data('filter');
    this.filter_buttons.removeClass('active');
    filter.addClass('active');
    if (filterType !== 'genre') {
      Spdy('#genre_select').removeClass('genre_select--show').addClass('genre_select--hidden');
      this.sort(filterType);
    } else {
      let genre_menu = Spdy('#genre_select');
      if (genre_menu.hasClass('genre_select--hidden')) {
        genre_menu.removeClass('genre_select--hidden').addClass('genre_select--show');
      } else {
        genre_menu.removeClass('genre_select--show').addClass('genre_select--hidden');
      }
      this.sort('genre', this.selected_options); // default sort when you first click the tab
    }
    // console.log('handleFilterClick', filterType);
  },

  createFilterButtons: function () {
    // console.log('createFilterButtons');
    let filter_menu = this.filter_menu;
    let hasFilterOptions = filter_menu && filter_menu.length > 0;

    if (hasFilterOptions) {
      let filter_buttons = this.filter_buttons;
      filter_buttons.on('click', this.handleFilterClick.bind(this));

      let hasGenreOptions = !!document.getElementById('genre_select');
      if (hasGenreOptions) {
        let genre_menu = Spdy('#genre_select');
        genre_menu.find('.genre_option').on('click', this.handleGenreClick.bind(this));
      }
    }
  },

  deActivateOverlayToggles: function () {
    Spdy('.programme .overlay .arrow').off('click');
  },
  activateOverlayToggles: function () {
    Spdy('.programme .overlay .arrow').on('click', function () {
      Spdy(Spdy(this).parent()).toggleClass('active');
    });
  },

  deActivateExternalFeatureClick() {
    Spdy('[data-externalfeatureid]').off('click');
  },
  activateExternalFeatureClick() {
    Spdy('[data-externalfeatureid]').on('click', function (e) {
      const externalfeatureid = this.dataset.externalfeatureid;
      triggerTracking('interaction-external-feature', {
        contenttitle: externalfeatureid,
        englishname: externalfeatureid,
        interaction: `telecom provider click`,
        interactionevent: 1,
      });
    });
  },
};

function createFeaturesLandingController(options) {
  return new FeaturesLandingController(options);
}

module.exports = createFeaturesLandingController;
