export const TURNER_METADATA = window.turner_metadata || false;

export const parseData = (data) => {
  // Needs to convert data to lowercase
  // I think it aslo needs to remove certain characters / not sure what tho at the moment
  return (data || '').toLowerCase();
};

export const getTrackingArray = () => {
  return window.turner_metadata && window.turner_metadata.trackAction
    ? window.turner_metadata.trackAction
    : [];
};

export const getDomain = () => {
  const hostname = window.location.hostname.replace('www.', '');
  const pathname = window.location.pathname;
  const domain = hostname + pathname;
  return parseData(domain);
};

export const AD_TEMPLATE = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};
