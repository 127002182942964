const createTemplates = (options) => {
  const CatchupItem = require('../../partials/CatchupItem.hbs');
  const CatchupPlayer = require('../../partials/CatchupPlayer.hbs');
  const DatePicker = require('../../partials/DatePicker.hbs');
  const FeatureLanding = require('../../partials/FeatureLanding.hbs');
  const NewsFeed = require('../../partials/NewsFeed.hbs');
  const SearchAutoComplete = require('../../partials/SearchAutoComplete.hbs');
  const TVGuide = require('../../partials/TVGuide.hbs');
  const TVGuideUpcomingCarousel = require('../../partials/TVGuideUpcomingCarousel.hbs');
  const videoPage = require('../../partials/videoPage.hbs');

  return {
    CatchupItem,
    CatchupPlayer,
    DatePicker,
    FeatureLanding,
    NewsFeed,
    SearchAutoComplete,
    TVGuide,
    TVGuideUpcomingCarousel,
    videoPage,
  };
};

function createHandleBarsTemplates(options) {
  return createTemplates(options);
}

module.exports = createHandleBarsTemplates;
