/* global __WATCH__:false */
import { Player, PlayerEvent, ErrorEvent } from 'bitmovin-player/modules/bitmovinplayer-core';
import BitmovinEngineModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import BitmovinNativeEngineModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import MSERendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import BitmovinXml from 'bitmovin-player/modules/bitmovinplayer-xml';
import HLSModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import ABRModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import DASHModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import MP4ContainerModule from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import TSContainerModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import BitmovinStyleBasic from 'bitmovin-player/modules/bitmovinplayer-style';
import BitmovinUI from 'bitmovin-player/modules/bitmovinplayer-ui';
import BitmovinAdvertisingCoreModule from 'bitmovin-player/modules/bitmovinplayer-advertising-core';
import BitmovinAdvertisingIMAModule from 'bitmovin-player/modules/bitmovinplayer-advertising-ima';
// import BitmovinPolyfill from 'bitmovin-player/modules/bitmovinplayer-polyfill';

import {
  AdMessageLabel,
  Container,
  ControlBar,
  FullscreenToggleButton,
  PlaybackToggleButton,
  Spacer,
  UIContainer,
  UIManager,
  VolumeSlider,
  VolumeToggleButton,
} from 'bitmovin-player-ui';
import {
  trackAdFinished,
  trackAdSkipped,
  trackAdStarted,
  trackFirstPlay,
  trackPaused,
  trackPlay,
  trackPlaybackFinished,
  trackStallEnded,
  trackStallStarted,
  trackTimeChanged,
  trackVideoReady,
} from 'modules/ensighten_tracking/video/ensightenVideo';
import { getLogOptions } from 'constants/logging';

Player.addModule(BitmovinEngineModule);
Player.addModule(BitmovinNativeEngineModule);
Player.addModule(MSERendererModule);
Player.addModule(BitmovinXml);
Player.addModule(HLSModule);
Player.addModule(ABRModule);
Player.addModule(DASHModule);
Player.addModule(MP4ContainerModule);
Player.addModule(TSContainerModule);
Player.addModule(BitmovinStyleBasic);
Player.addModule(BitmovinUI);
Player.addModule(BitmovinAdvertisingCoreModule);
Player.addModule(BitmovinAdvertisingIMAModule);

const BITMOVIN_FALLBACK = {
  dash: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
  hls: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
  progressive:
    'https://bitdash-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
  poster: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
};

export default class BitmovinPlayer {
  constructor({ avsData = false, prerollOnly = false, domElementId = 'bitmovin-player' }) {
    this.log = getLogOptions({ id: 'bitmovinPlayer' });
    this.domElementId = domElementId;
    this.enableEnsightenTracking = false;
    this.player = null;
    this.imaUIManager = null;
    this.state = {
      firstPlay: true,
      completed: false,
    };
    this.src = null;
    this.ads = [];
    this.prerollOnly = prerollOnly;
    this.avsData = avsData;

    if (prerollOnly) {
      const { src } = avsData;
      this.src = src;
      this.setupPlayer();
      return;
    }

    if (avsData && !prerollOnly) {
      this._getAVSData(this.avsData)
        .done((response) => {
          const { message, resultCode, resultObj } = response;
          if (Object.keys(resultObj).length === 0) {
            console.error(`${this.log.prep} AVS response: ${message}`, this.log.clr);
          } else {
            this.src = resultObj?.src ?? '';
            this.log.enabled &&
              console.log(`${this.log.prep} avs response ${resultCode}`, this.log.clr, this.src);
            this.setupPlayer();
          }
        })
        .fail((err) => {
          console.error(
            `${this.log.prep} AVS response errored : Running off a VHOST? Try using localhost`,
            this.log.clr
          );
          this.setupPlayer();
        });
    } else {
      console.warn(`${this.log.prep} window.avs_video data missing`, this.log.clr);
      this.setupPlayer();
    }

    this.log.enabled &&
      console.log(`${this.log.prep} init | domElementId ${domElementId}`, this.log.clr);
  }

  playVideo({ avsData, prerollOnly = false }) {
    this.log.enabled &&
      console.log(`${this.log.prep} playVideo | video_id ${avsData?.video_id}`, this.log.clr);
    this.avsData = avsData;
    this.state = {
      firstPlay: true,
      completed: false,
    };
    if (avsData && !prerollOnly) {
      this._getAVSData(this.avsData)
        .done((response) => {
          const { message, resultCode, resultObj } = response;
          if (Object.keys(resultObj).length === 0) {
            console.error(`${this.log.prep} AVS response: ${message}`, this.log.clr);
          } else {
            this.src = resultObj?.src ?? '';
            this.log.enabled &&
              console.log(`${this.log.prep} avs response ${resultCode}`, this.log.clr, this.src);
            this.setupPlayer();
          }
        })
        .fail((err) => {
          console.warn(`${this.log.prep} window.avs_video data missing`, this.log.clr);
          this.setupPlayer();
        });
    } else {
      console.warn(`${this.log.prep} window.avs_video data missing`, this.log.clr);
      this.setupPlayer();
    }
  }

  stopVideo() {
    this.player && this.player.pause();
  }

  setupPlayer(forceFallback = false) {
    const { advertising_slug, poster_url, bitmovin_ad_tag } = this.avsData;
    const playerConfig = this._getConfig();
    const playerContainer = document.getElementById(this.domElementId);
    if (document.getElementById('video-placeholder')) {
      document.getElementById('video-placeholder').classList.add('hide');
    }
    this.player = this.player || new Player(playerContainer, playerConfig);
    this.imaUIManager = this.imaUIManager || this._getUIManager(this.player, advertising_slug);

    const useFallback = (__WATCH__ && location.hostname !== 'localhost') || forceFallback;
    const playerSource = useFallback
      ? BITMOVIN_FALLBACK
      : this.prerollOnly
      ? {
          progressive: [
            {
              url: this.src,
              type: 'video/mp4',
            },
          ],
          poster: poster_url,
        }
      : {
          hls: this.src,
          poster: poster_url,
        };
    this.addListeners();
    this.player.load(playerSource).then(
      () => {
        this.log.enabled && console.log(`${this.log.prep} player.load`, this.log.clr);
        if (this.enableEnsightenTracking) {
          this.onReady();
        }
      },
      () => {
        this.onLoadError();
      }
    );
    // this.player.setLogLevel('debug');
    this.log.enabled &&
      console.log(`${this.log.prep} setupPlayer | prerollOnly ${this.prerollOnly}`, this.log.clr);
  }

  pause() {
    this.player && this.player.pause();
  }
  play() {
    this.player && this.player.play();
  }

  addEnsightenTracking() {
    this.enableEnsightenTracking = true;
    this.addListeners();
  }

  onReady() {
    this.log.enabled && console.log(`${this.log.prep} onReady`, this.log.clr);
    const duration = this.player.getDuration(); // video duration
    trackVideoReady({ duration });
  }
  onAdManifestLoaded({ adConfig = {} }) {
    const { ads = [] } = adConfig;
    this.ads = ads;
    this.log.enabled && console.log(`${this.log.prep} onAdManifestLoaded`, this.log.clr, adConfig);
  }
  onAdStarted(e) {
    this.log.enabled && console.log(`${this.log.prep} onAdStarted`, this.log.clr);
    this.imaUIManager.currentUi.onControlsShow.dispatch(this.imaUIManager);
    const duration = this.player.getDuration(); // video duration
    trackAdStarted({ duration });
  }
  onAdSkipped(e) {
    this.log.enabled && console.log(`${this.log.prep} onAdSkipped`, this.log.clr);
    trackAdSkipped({});
  }
  onAdFinished(e) {
    this.log.enabled && console.log(`${this.log.prep} onAdFinished`, this.log.clr);
    trackAdFinished({});
  }
  /**
   * onAdError is used for when an ad Tag exists but the response from the server when retrieving the tag is an error
   * helps with this : message: "The VAST response document is empty."
   */
  onAdError(e) {
    this.log.enabled && console.log(`${this.log.prep} onAdError`, this.log.clr);
    trackFirstPlay({});

    if (this.prerollOnly) {
      /**
       * IMA seems to be erroring ('undefined on object ( c.getVastMediaHeight() )') on iOS 9 and lower : preventing games from playing
       * On adError destroy player and dispatch event
       */
      this.dispatchVideoCompleteEvent();
      this.destroy();
    }
  }
  onLoadError() {
    console.warn(`${this.log.prep} onLoadError`, this.log.clr);
    this.state.completed = true;
    if (this.prerollOnly) {
      this.dispatchVideoCompleteEvent();
      this.destroy();
    }
  }
  onPlayerError(error) {
    console.warn(`${this.log.prep} onPlayerError : ${error.name}`, this.log.clr, error);
    if (this.prerollOnly) {
      this.dispatchVideoCompleteEvent();
      this.destroy();
    }
  }

  onPlaybackFinished(e) {
    this.log.enabled && console.log(`${this.log.prep} onPlaybackFinished`, this.log.clr);
    this.dispatchVideoCompleteEvent();
    this.state.completed = true;
    if (this.prerollOnly) {
      this.destroy();
    } else {
      trackPlaybackFinished({});
    }
  }
  onPlaybackFinishedNoTracking() {
    this.log.enabled && console.log(`${this.log.prep} onPlaybackFinishedNoTracking`, this.log.clr);
    this.dispatchVideoCompleteEvent();
    this.state.completed = true;
  }
  onPlay(e) {
    this.log.enabled && console.log(`${this.log.prep} onPlay`, this.log.clr);
  }
  onPlaying(e) {
    const hasAd = this.ads && this.ads.length > 0;
    this.log.enabled && console.log(`${this.log.prep} onPlaying | hasAd ${hasAd}`, this.log.clr);
    if (this.state.firstPlay) {
      this.state.firstPlay = false;
      if (!hasAd) {
        trackFirstPlay({});
      }
    } else if (this.state.completed) {
      const duration = this.player.getDuration(); // video duration
      trackVideoReady({ duration });
      trackFirstPlay({});
      this.state.completed = false;
    } else {
      trackPlay({});
    }
  }
  onPaused(e) {
    this.log.enabled && console.log(`${this.log.prep} onPaused`, this.log.clr);
    trackPaused({});
  }
  onTimeChanged({ time }) {
    trackTimeChanged({ time });
  }
  onStallStarted(e) {
    trackStallStarted({});
  }
  onStallEnded(e) {
    trackStallEnded({});
  }
  onAdBreakFinished(e) {
    this.log.enabled && console.log(`${this.log.prep} onAdBreakFinished`, this.log.clr);
    this.dispatchVideoCompleteEvent();
    this.destroy();
  }

  addListeners() {
    if (this.player && this.prerollOnly) {
      this.addPreRollEventListeners();
    } else if (this.player && this.enableEnsightenTracking) {
      this.addPlayerEventListeners();
    } else if (this.player && !this.enableEnsightenTracking) {
      this.player.on(PlayerEvent.PlaybackFinished, this.onPlaybackFinishedNoTracking.bind(this));
    }
  }

  removeListeners() {
    if (this.player && this.prerollOnly) {
      this.removePreRollEventListeners();
    } else if (this.player && this.enableEnsightenTracking) {
      this.removePlayerEventListeners();
    } else if (this.player && !this.enableEnsightenTracking) {
      this.player.off(PlayerEvent.PlaybackFinished, this.onPlaybackFinishedNoTracking.bind(this));
    }
  }

  addPreRollEventListeners() {
    this.log.enabled && console.log(`${this.log.prep} addPreRollEventListeners`, this.log.clr);
    this.player.on(PlayerEvent.Error, this.onPlayerError.bind(this));
    this.player.on(PlayerEvent.Play, this.onPlay.bind(this));
    this.player.on(PlayerEvent.AdBreakFinished, this.onAdBreakFinished.bind(this));
    this.player.on(PlayerEvent.PlaybackFinished, this.onPlaybackFinished.bind(this));
  }

  removePreRollEventListeners() {
    this.log.enabled && console.log(`${this.log.prep} removePreRollEventListeners`, this.log.clr);
    this.player.off(PlayerEvent.Error, this.onPlayerError.bind(this));
    this.player.off(PlayerEvent.Play, this.onPlay.bind(this));
    this.player.off(PlayerEvent.AdBreakFinished, this.onAdBreakFinished.bind(this));
    this.player.off(PlayerEvent.PlaybackFinished, this.onPlaybackFinished.bind(this));
  }

  addPlayerEventListeners() {
    this.log.enabled && console.log(`${this.log.prep} addPlayerEventListeners`, this.log.clr);
    this.player.on(PlayerEvent.Error, this.onPlayerError.bind(this));
    this.player.on(PlayerEvent.Play, this.onPlay.bind(this));
    this.player.on(PlayerEvent.AdManifestLoaded, this.onAdManifestLoaded.bind(this));
    this.player.on(PlayerEvent.AdStarted, this.onAdStarted.bind(this));
    this.player.on(PlayerEvent.AdSkipped, this.onAdSkipped.bind(this));
    this.player.on(PlayerEvent.AdFinished, this.onAdFinished.bind(this));
    this.player.on(PlayerEvent.AdError, this.onAdError.bind(this));
    this.player.on(PlayerEvent.PlaybackFinished, this.onPlaybackFinished.bind(this));
    this.player.on(PlayerEvent.Playing, this.onPlaying.bind(this));
    this.player.on(PlayerEvent.Paused, this.onPaused.bind(this));
    this.player.on(PlayerEvent.TimeChanged, this.onTimeChanged.bind(this));
    this.player.on(PlayerEvent.StallStarted, this.onStallStarted.bind(this));
    this.player.on(PlayerEvent.StallEnded, this.onStallEnded.bind(this));
  }

  removePlayerEventListeners() {
    this.log.enabled && console.log(`${this.log.prep} removePlayerEventListeners`, this.log.clr);
    this.player.off(PlayerEvent.Error, this.onPlayerError.bind(this));
    this.player.off(PlayerEvent.Play, this.onPlay.bind(this));
    this.player.off(PlayerEvent.AdManifestLoaded, this.onAdManifestLoaded.bind(this));
    this.player.off(PlayerEvent.AdStarted, this.onAdStarted);
    this.player.off(PlayerEvent.AdSkipped, this.onAdSkipped);
    this.player.off(PlayerEvent.AdFinished, this.onAdFinished);
    this.player.off(PlayerEvent.AdError, this.onAdError.bind(this));
    this.player.off(PlayerEvent.AdBreakFinished, this.onAdBreakFinished);
    this.player.off(PlayerEvent.PlaybackFinished, this.onPlaybackFinished);
    this.player.off(PlayerEvent.Playing, this.onPlaying.bind(this));
    this.player.off(PlayerEvent.Paused, this.onPaused);
    this.player.off(PlayerEvent.TimeChanged, this.onTimeChanged);
    this.player.off(PlayerEvent.StallStarted, this.onStallStarted);
    this.player.off(PlayerEvent.StallEnded, this.onStallEnded);
  }

  dispatchVideoCompleteEvent(type = 'bitmovin_finished') {
    const completeEvent = document.createEvent('Event');
    completeEvent.initEvent(type, true, true);
    document.dispatchEvent(completeEvent);
  }

  destroy() {
    this.log.enabled && console.log(`${this.log.prep} destroy`, this.log.clr);
    this.removeListeners();
    this.ads = null;
    this.player = null;
    this.imaUIManager = null;
    this.src = null;
  }

  _getUIManager(player, advertising_slug) {
    return new UIManager(player, [
      {
        ui: new UIContainer({
          hideDelay: -1, // always show UI
          components: [
            new Container({
              components: [
                new AdMessageLabel({ text: advertising_slug }),
                //new AdSkipButton(),
              ],
              cssClass: 'ui-ads-status',
            }),
            new ControlBar({
              components: [
                new Container({
                  components: [
                    new PlaybackToggleButton(),
                    new VolumeToggleButton(),
                    new VolumeSlider(),
                    new Spacer(),
                    new FullscreenToggleButton(),
                  ],
                  cssClasses: ['controlbar-bottom'],
                }),
              ],
              cssClasses: ['adControlBar'],
            }),
          ],
          cssClasses: ['ui-skin-ads'],
        }),
        condition: function (context) {
          // Show the ads UI for every ad by ignoring the context.adRequiresUi flag
          return context.isAd;
        },
      },
    ]);
  }

  _getConfig() {
    // const cookiePolicy = window.TURNERENTS.COOKIE_POLICY || {};
    // const oneTrustActive = cookiePolicy.oneTrustActive || false;
    // const advertisingCookiesEnabled = cookiePolicy.advertisingCookiesEnabled || false;
    //
    const { bitmovin_key, bitmovin_ad_tag, autoplay = false, staticUrl } = this.avsData;
    //
    // let playAd = true; // default to always play video prerolls
    // if (oneTrustActive) {
    //   playAd = advertisingCookiesEnabled; // if oneTrustActive only play video prerolls if advertisingCookiesEnabled = true
    // }

    let playAd = false;

    const conf = {
      key: bitmovin_key,
      analytics: false,
      playback: {
        autoplay,
        muted: autoplay,
      },
      location: {
        ui: `${staticUrl}bitmovin/bitmovinplayer-ui.min.js`,
        ui_css: `${staticUrl}bitmovin/bitmovinplayer-ui.min.css`,
      },
    };
    if (bitmovin_ad_tag && playAd) {
      conf.advertising = {
        adBreaks: [
          {
            tag: {
              url: bitmovin_ad_tag,
              type: 'vast',
            },
            id: 'pre-roll-ad',
            position: 'pre',
          },
        ],
      };
    }
    return conf;
  }

  _getAVSData(avsData) {
    const { avs_video_play_url, video_id } = avsData;
    let url = avs_video_play_url.replace('{format}', 'PCTV').replace('{id}', video_id);
    return $.ajax({
      url: url,
      crossDomain: true,
      method: 'GET',
      dataType: 'json',
    });
  }
}
