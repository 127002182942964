let NewsFeedController = function (options) {
  let t = this;
  t.container = options.container;
  t.staticUrl = options.static_url;
  t.dynamicUrl = options.dynamic_url;
  t.locale = options.locale;
  t.apiUrl = options.api_url;
  t.newsData = {};
  t.template = options.template;

  $.ajax({
    url: t.apiUrl,
    type: 'POST',
    dataType: 'json',
    success: function (_data) {
      t.newsData = _data;
      t.create();
    },
  });
  return t;
};

NewsFeedController.prototype = {
  create: function () {
    let t = this;
    t.container.html(
      t.template({
        locale: t.locale,
        staticUrl: t.static_url,
        dynamicUrl: t.dynamic_url,
        newsData: t.newsData,
      })
    );
  },

  destroy: function () {
    let t = this;
  },
};

function createNewsFeedController(options) {
  return new NewsFeedController(options);
}

module.exports = createNewsFeedController;
