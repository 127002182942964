var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"guide\" style=\"z-index:"
    + alias3((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,6,"-",(data && lookupProperty(data,"index")),{"name":"math","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":57}}}))
    + ";\" data-guide=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":82}}}) : helper)))
    + "\">\n		<div class=\"current_time\" ><div class=\"arrow\"></div></div>\n		<span class=\"time theme_bg_clr\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"hour") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":8,"column":12}}})) != null ? stack1 : "")
    + "		</span><!--\n	 --><span class=\"shows\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"item") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":19,"column":12}}})) != null ? stack1 : "")
    + "		</span>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"primetime") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"digits") : depth0), depth0))
    + ":00</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<span style=\"height:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"height_px") : depth0), depth0))
    + "px;height:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"height_rem") : depth0), depth0))
    + "rem;\" class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"duplicate_entry") : depth0), depth0))
    + "\" >\n				<span data-episodetitle=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"episode_title") : depth0), depth0))
    + "\" data-certification=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"certification") : depth0), depth0))
    + "\" data-link=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" data-season=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"season") : depth0), depth0))
    + "\" data-episode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"episode") : depth0), depth0))
    + "\" data-episode_synopsis=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"episode_synopsis") : depth0), depth0))
    + "\" data-end=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"end_time_pretty") : depth0), depth0))
    + "\" data-start=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"start_time_pretty") : depth0), depth0))
    + "\" data-duration=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "\" class=\"click_activator\"></span>\n				<span class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tvguide_show_times") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "			</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<span class=\"time\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"time_range") : depth0), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"guide_container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":22,"column":10}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});