/* global __WATCH__:false, __SERVICE_WORKER__:false, __GA__:false */
/**
 * Moment.js
 *
 * ALERT : Use this to Turn off the depreciation warnings we are getting in moment - the issue really needs fixing though
 *
 * http://momentjs.com/guides/#/warnings/js-date/
 * https://stackoverflow.com/questions/23263380/deprecation-warning-moment-construction-falls-back-to-js-date
 *
 * Moment construction falls back to js Date.
 * This is discouraged and will be removed in an upcoming major release.
 * This deprecation warning is thrown when no known format is found for a date passed into the string constructor. To work around this issue, specify a format for the string being passed to moment().
 */
// moment.suppressDeprecationWarnings = true;

import '../stylus/mainvertical.styl';

// css added to stylus/perfect_scrollbar.styl
import PerfectScrollbar from 'perfect-scrollbar';

import { DOMReady } from 'utils/dom';
import { ensightenTrackingSetup } from 'modules/ensighten_tracking/EnsightenTracking';
import { getLogOptions } from 'constants/logging';
import { oneTrustIsActive } from 'utils/onetrust';

const createCarouselWidget = require('modules/CarouselWidget');
const createFeatureDetailController = require('modules/FeatureDetailController');
const createGenericPopupContorller = require('modules/GenericPopupController');
const createNewsFeedController = require('modules/NewsFeedController');
const createSearchAutoCompleteController = require('modules/SearchAutoCompleteController');
const createVideoInlineController = require('modules/VideoInlineController');
const createVideoPopupController = require('modules/VideoPopupController');
const createVideoPageController = require('modules/VideoPageController');
const createTVGuideController = require('modules/TVGuideController');
const createFeaturesLandingController = require('modules/FeaturesLandingController');
const createShareController = require('modules/ShareController');
const createHandleBarsTemplates = require('modules/HandlebarsTemplates');
const Cookies = require('js-cookie');
const Spdy = require('modules/Speedy');

const TURNERENTS = window.TURNERENTS || {};

const { enabled: log, clr: logClr, prep } = getLogOptions({ id: 'bootstrap' });

const oneTrustBannerInDom = () => {
  const otBanner = document.getElementById('onetrust-banner-sdk');
  if (otBanner && window.getComputedStyle(otBanner, null).getPropertyValue('display') == 'block') {
    return true;
  }
  return false;
};

const updateCookiePolicyData = ({ domLoaded = false, oneTrustInit = false }) => {
  const cookiePolicy = window.TURNERENTS.COOKIE_POLICY || {};
  domLoaded && (cookiePolicy.domLoaded = true);
  oneTrustInit && (cookiePolicy.oneTrustInit = true);
  cookiePolicy.oneTrustBannerExists = oneTrustBannerInDom();
  const otActiveGroups = window.OnetrustActiveGroups ? window.OnetrustActiveGroups.split(',') : [];
  cookiePolicy.performanceCookiesEnabled = otActiveGroups.indexOf('pf') !== -1;
  cookiePolicy.functionalCookiesEnabled = otActiveGroups.indexOf('fc') !== -1;
  cookiePolicy.advertisingCookiesEnabled = otActiveGroups.indexOf('tc') !== -1;

  const {
    oneTrustActive,
    domLoaded: dl,
    oneTrustInit: ot,
    performanceCookiesEnabled: pc,
    functionalCookiesEnabled: fc,
    advertisingCookiesEnabled: ac,
    oneTrustBannerExists,
  } = cookiePolicy;

  if (log) {
    let out = `${prep} updateCookiePolicyData`;
    oneTrustActive && (out = out + `\noneTrustActive \t\t ${oneTrustActive}`);
    dl && (out = out + `\ndomLoaded \t\t\t ${dl}`);
    ot && (out = out + `\noneTrustInit \t\t ${ot}`);
    oneTrustBannerExists && (out = out + `\noneTrustBannerExists \t ${oneTrustBannerExists}`);
    const cp = `\ncookies \t\t\t performance : ${pc} | functional : ${fc} | advertising : ${ac}`;
    out = out + cp;
    console.log(out, logClr);
  }

  return cookiePolicy;
};

const addOneTrustListeners = () => {
  document.body.addEventListener('click', function (evt) {
    const { id, classList } = evt.target;
    const classCheckList = 'save-preference-btn-handler,ot-pc-refuse-all-handler';
    const idCheckList =
      'onetrust-accept-btn-handler,accept-recommended-btn-handler,onetrust-reject-all-handler,ot-pc-refuse-all-handler';
    if (classList) {
      for (const classClick of classList) {
        if (classClick && classCheckList.includes(classClick)) {
          location.reload();
        }
      }
    }
    if (id && id !== '' && idCheckList.includes(id)) {
      location.reload();
    }
  });
};

//
// EXTRACTED RICH FUNCTIONS
//
const sizeDropdownMenus = () => {
  Spdy('.select_menu .dropdown').each(function (index, item) {
    let children = Spdy(item).find('li');
    if (children.length >= 5) {
      let childrenHeight = Spdy(children.elements[0]).outerHeight();
      Spdy(item).css('height', childrenHeight * 4 + 'px');
    }
  });
  let dropcolour = $('.inline_video_container').attr('data-dropcolour');
  $('.select_menu .selected').css('color', dropcolour);
  $('.select_menu .dropdown a').css('color', dropcolour);
};

const getHashVariable = (variable) => {
  let query = window.location.hash.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
};

const removeLoading = () => {
  setTimeout(function () {
    Spdy('.loading').removeClass('active');
  }, 500);
};

const fitText = () => {
  // fit text -- Breaks mobile atm
  let minFontSize = 18;
  $('.w1240 .overlay .name').each(function () {
    let f;
    while ($(this).height() > $(this).parent().height()) {
      f = parseInt($(this).css('font-size'));
      if (f <= minFontSize) return;
      // console.log($(this).height() , $(this).parent().height(), f);
      $(this).css('font-size', --f + 'px');
    }
  });
};

//
// REWORKED BOOTSTRAP
//
const bootstrapDOM = () => {
  log && console.log(`${prep} bootstrapDOM`, logClr);

  const HandlebarsTemplates = createHandleBarsTemplates({});
  window.HandlebarsTemplates = HandlebarsTemplates;

  TURNERENTS.createVideoButtonClick = function () {
    Spdy('[data-videoid]').on('click', function (e) {
      Spdy.preventDefault(e);
      TURNERENTS.popupVideoController &&
        TURNERENTS.popupVideoController.openVideo({
          type: Spdy(this).data('videotype'),
          id: Spdy(this).data('videoid'),
          tracking_name: Spdy(this).data('videoname'),
        });
    });
  };
  TURNERENTS.destroyVideoButtonClick = function () {
    Spdy('[data-videoid]').off('click');
  };

  TURNERENTS.createInlineVideoButtonClick = function () {
    Spdy('[data-inlinevideoid]').on('click', function (e) {
      Spdy.preventDefault(e);
      Spdy('[data-inlinevideoid]').removeClass('active');
      Spdy(this).addClass('active');
      TURNERENTS.inlineVideoController.openVideo({
        type: Spdy(this).data('videotype'),
        id: Spdy(this).data('inlinevideoid'),
        tracking_name: Spdy(this).data('videoname'),
      });
    });
  };
  TURNERENTS.destroyInlineVideoButtonClick = function () {
    Spdy('[data-inlinevideoid]').off('click');
  };

  Spdy('.overlay .arrow').on('click', function () {
    Spdy(Spdy(this).parent()).toggleClass('active');
  });

  TURNERENTS.generic_popup = createGenericPopupContorller({
    copy: TURNERENTS.footer_copy,
  });

  // CHECK TO SEE IF WE NEED TO AUTO-OPEN A POPUP BASED ON HASH QUERY
  let hash = window.location.hash.substring(1);
  Spdy('.links [data-trigger]').each(function (index, item) {
    if (Spdy(item).data('trigger') == hash) {
      TURNERENTS.generic_popup.onPopupOpen(item);
    }
  });

  //setup inital video buttons (video thumbnails)
  TURNERENTS.destroyVideoButtonClick();
  TURNERENTS.createVideoButtonClick();

  TURNERENTS.destroyInlineVideoButtonClick();
  TURNERENTS.createInlineVideoButtonClick();

  resizeCallback();
  Spdy(window).on('resize', function () {
    resizeCallback();
  });
  Spdy(window).on('orientationChange', function () {
    resizeCallback();
  });

  //If TV Guide data variable exists (was populated by php page) , then attempt to load guide
  if (TURNERENTS.TVGUIDE_DATA) {
    TURNERENTS.tv_guide = createTVGuideController({
      locale: TURNERENTS.language_code,
      guide_template: HandlebarsTemplates.TVGuide,
      date_picker_template: HandlebarsTemplates.DatePicker,
      upcoming_carousel_template: HandlebarsTemplates.TVGuideUpcomingCarousel,
      guide_container: Spdy('.tv_guide_container'),
      date_picker_container: Spdy('.date_picker_container'),
      tv_data_url: TURNERENTS.TVGUIDE_DATA.tv_data_url,
      tvguide_lookup: TURNERENTS.TVGUIDE_DATA.tvguide_lookup,
      primetime: TURNERENTS.TVGUIDE_DATA.primetime,
      cms_calendar: TURNERENTS.TVGUIDE_DATA.calendar,
      date_format: TURNERENTS.TVGUIDE_DATA.date_format,
      height_per_hour: 150, //1.5rem height multiplier,
      section_data: TURNERENTS.TVGUIDE_DATA.section_data,
      backup_image: TURNERENTS.TVGUIDE_DATA.backup_image || null,
    });
  }

  //If FEATURE_LANDING_DATA data variable exists (was populated by php page) , then attempt to load guide
  if (TURNERENTS.FEATURE_LANDING_DATA) {
    TURNERENTS.feature_landing = createFeaturesLandingController({
      static_url: TURNERENTS.static_url,
      dynamic_url: TURNERENTS.dynamic_url,
      locale: TURNERENTS.language_code,
      friendly_url: TURNERENTS.FEATURE_LANDING_DATA.friendly_url,
      data: TURNERENTS.FEATURE_LANDING_DATA.data,
      filters: TURNERENTS.FEATURE_LANDING_DATA.filters,
      feature_template: HandlebarsTemplates.FeatureLanding,
      filtersGenreKeys: TURNERENTS.FEATURE_LANDING_DATA.filtersGenreKeys,
    });
  }
  if (TURNERENTS.FEATURE_DETAILS_DATA) {
    TURNERENTS.feature_detail_controller = createFeatureDetailController();
  }

  if (TURNERENTS.news_api && Spdy('.news_feed').length > 0) {
    Spdy('.news_feed').each(function (index, item) {
      createNewsFeedController({
        container: Spdy(item),
        static_url: TURNERENTS.static_url,
        dynamic_url: TURNERENTS.dynamic_url,
        locale: TURNERENTS.language_code,
        api_url: TURNERENTS.news_api,
        template: HandlebarsTemplates.NewsFeed,
      });
    });
  }

  if (TURNERENTS.show_lookup && Spdy('.search_autocomplete').length > 0) {
    Spdy('.search_autocomplete').each(function (index, item) {
      createSearchAutoCompleteController({
        container: Spdy(item),
        static_url: TURNERENTS.static_url,
        dynamic_url: TURNERENTS.dynamic_url,
        locale: TURNERENTS.language_code,
        data: TURNERENTS.show_lookup,
        inputField: Spdy('#mainnav_search'),
        template: HandlebarsTemplates.SearchAutoComplete,
      });
    });
  }

  Spdy('.carousel_widget.homepage_carousel').each(function (index, item) {
    let newCarousel = createCarouselWidget({
      carousel: Spdy(item),
      carousel_area: Spdy(item).find('.carousel_area'),
      children_wrapper: Spdy(item).find('.carousel_wrapper'),
      carousel_children: Spdy(item).find('.carousel_item'),
      arrows: true,
      dragging: true,
      autoscroll: true,
      controls_container: Spdy(item).find('.controls_container'),
    });
    newCarousel.init();
  });

  Spdy('.carousel_widget.related_movies--carousel').each(function (index, item) {
    let newCarousel = createCarouselWidget({
      carousel: Spdy(item),
      carousel_area: Spdy(item).find('.carousel_area'),
      children_wrapper: Spdy(item).find('.carousel_wrapper'),
      carousel_children: Spdy(item).find('.carousel_item'),
      arrows: true,
      center_mode: true,
      dragging: true,
      clone_items: true,
      autoscroll: true,
      controls_container: Spdy(item).find('.controls_container'),
    });
    newCarousel.init();
  });

  // PERFECT SCROLLBAR : https://github.com/utatti/perfect-scrollbar
  Spdy('.perfect_scrollbar').each(function (index, item) {
    new PerfectScrollbar(item, {
      wheelSpeed: 2,
      wheelPropagation: false,
      minScrollbarLength: 20,
    });
  });

  TURNERENTS.sharer = createShareController({
    device: TURNERENTS.device,
  });

  Spdy('.btn_nav_close').on('click', function () {
    Spdy(Spdy(this).parent()).removeClass('active');
    Spdy('.mobile_nav_button').removeClass('active');
    Spdy('.search_autocomplete').removeClass('active');
  });

  function resizeCallback() {
    fitText();
    removeLoading();
    sizeDropdownMenus();
  }

  /**
   * OLD COOKIE BAR
   * Left in for backwards compatability!
   * if TURNERENTS.oneTrustActive then this will be disabled
   */
  function checkHash() {
    let hash = window.location.hash.substring(1);
    Spdy('.links [data-trigger]').each(function (index, item) {
      if (Spdy(item).data('trigger') == hash) {
        TURNERENTS.generic_popup.onPopupOpen(item);
      }
    });
  }
  if (!oneTrustIsActive()) {
    setTimeout(function () {
      if (!Cookies.get('turner_cookies_accept')) {
        Spdy('.cookie_bar').addClass('active');
        Spdy('.cookie_bar .agree_button').on('click', function (e) {
          Cookies.set('turner_cookies_accept', 'true');
          Spdy('.cookie_bar .pre_accept').removeClass('active');
          Spdy('.cookie_bar .post_accept').addClass('active');
        });
        Spdy('.cookie_bar .hide_button').on('click', function (e) {
          Spdy('.cookie_bar').removeClass('active');
          if (!Modernizr.preserve3d) {
            Spdy('.cookie_bar').css('display', 'none');
          }
        });
        Spdy('.cookie_bar .cookie_more').on('click', function (e) {
          // console.log('Check URL');
          checkHash();
        });
      } else {
        Spdy('.cookie_bar').css('display', 'none');
      }
    }, 500);
  }
  // OLD COOKIE BAR END

  //Internal Ad tracking
  //Extra click tracking for external ads
  Spdy('[data-clickadid]').on('click', function () {
    $.ajax({
      url:
        TURNERENTS.site_url +
        'ads/click/' +
        TURNERENTS.language_code +
        '/' +
        Spdy(this).data('clickadid'),
      success: function (_data) {},
    });
  });
  Spdy('[data-adid]').each(function (index, item) {
    $.ajax({
      url:
        TURNERENTS.site_url +
        'ads/view/' +
        TURNERENTS.language_code +
        '/' +
        Spdy(item).data('adid'),
      success: function (_data) {},
    });
  });

  function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }

  const showMoreButton = document.getElementById('show-more-specials');

  if (showMoreButton) {
    showMoreButton.addEventListener('click', function (e) {
      e.preventDefault();
      let wrappers = document
        .getElementById('specials__wrapper')
        .querySelectorAll('[data-wrapper-index]');
      let BreakException = {};
      try {
        wrappers.forEach(function (element) {
          if (hasClass(element, 'wrapper--hidden')) {
            element.classList.remove('wrapper--hidden');
            element.classList.add('wrapper--show');
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      let hidden = document
        .getElementById('specials__wrapper')
        .getElementsByClassName('wrapper--hidden');
      if (hidden.length <= 0) {
        document.getElementsByClassName('button__show-more')[0].classList.add('hidden');
      }
    });
  }
};

const bootstrapInLineVideo = () => {
  /*
   * TURNERENTS-530 NOTE : YT Inline video amends
   * Wait until we have received onetrust_init (async)
   * > then initialise video
   *
   */
  // CHECK TO SEE IF WE NEED TO AUTO-OPEN A VIDEO POPUP BASED ON HASH QUERY
  if (Spdy('.popup.video').elements.length > 0) {
    TURNERENTS.popupVideoController = createVideoPopupController({});
  }

  TURNERENTS.inlineVideoController = createVideoInlineController({});
  if (TURNERENTS.VIDEO_PAGE_DATA) {
    TURNERENTS.video_page_controller = createVideoPageController({
      static_url: TURNERENTS.static_url,
      dynamic_url: TURNERENTS.dynamic_url,
      locale: TURNERENTS.language_code,
      data: TURNERENTS.VIDEO_PAGE_DATA.data,
      videoPageTemplate: HandlebarsTemplates.videoPage,
      container: Spdy('.inline_video_container'),
      onInit: function () {
        // INITIALISE FIRST VIDEO AVAILBILE OR VIDEO FROM URL HASH
        if (getHashVariable('inlinevideoid')) {
          TURNERENTS.inlineVideoController.openVideo({
            type: getHashVariable('videotype'),
            id: getHashVariable('inlinevideoid'),
            tracking_name: getHashVariable('videoname'),
          });
        } else if (Spdy('[data-inlinevideoid]').length > 0) {
          let firstVideoOption = Spdy(Spdy('[data-inlinevideoid]').elements[0]);
          log &&
            console.log(
              `${prep} VIDEO_PAGE_DATA start inlinevideoid`,
              logClr,
              firstVideoOption.data('videotype')
            );
          TURNERENTS.inlineVideoController.openVideo({
            type: firstVideoOption.data('videotype'),
            id: firstVideoOption.data('inlinevideoid'),
            tracking_name: firstVideoOption.data('videoname'),
          });
        }
        sizeDropdownMenus();
      },
    });
  } else {
    if (Spdy('[data-inlinevideoid]').length > 0) {
      let firstVideoOption = Spdy(Spdy('[data-inlinevideoid]').elements[0]);
      log && console.log(`${prep} start inlinevideoid`, logClr, firstVideoOption.data('videotype'));
      TURNERENTS.inlineVideoController.openVideo({
        type: firstVideoOption.data('videotype'),
        id: firstVideoOption.data('inlinevideoid'),
        tracking_name: firstVideoOption.data('videoname'),
        useDomID: true,
      });
    }
  }
};

const handleDomLoaded = () => {
  log && console.log(`${prep} handleDomLoaded`, logClr);
  updateCookiePolicyData({ domLoaded: true });
  addOneTrustListeners();
  if (TURNERENTS.analyticsEnabled) {
    // initialise tracking if oneTrust is disabled via CMS
    ensightenTrackingSetup();
  }
  if (!oneTrustIsActive()) {
    // if OT is not active then bootstrap inline video
    bootstrapInLineVideo();
  }
};

const handleOneTrustInit = () => {
  log && console.log(`${prep} handleOneTrustInit`, logClr);
  updateCookiePolicyData({ oneTrustInit: true });
  bootstrapInLineVideo();
};

const handleYoutubeAPIready = () => {
  log && console.log(`${prep} handleYoutubeAPIready`, logClr);
  TURNERENTS.inlineVideoController && TURNERENTS.inlineVideoController.youTubeApiReady();
  TURNERENTS.popupVideoController && TURNERENTS.popupVideoController.youTubeApiReady();
};

// LISTENERS
document.addEventListener('onetrust_init', handleOneTrustInit);
document.addEventListener('youtubeAPIready', handleYoutubeAPIready);
window.addEventListener('load', handleDomLoaded);

/**
 * When dom is ready initialise
 */
DOMReady(() => {
  log && console.log(`${prep} DOM Ready`, logClr);
  bootstrapDOM();
});
