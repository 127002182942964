/* 
       REQUIRES MOMENTS.JS - USE FOR:
       *Easy Formatting of time / dates
       *Cross browser Date parsing
       *Easy time/date calculations 
    */

const moment = require('moment');
const Handlebars = require('handlebars/runtime');
const createCarouselWidget = require('modules/CarouselWidget');
const Pikaday = require('pikaday');

let TVGuideController = function (options) {
  let t = this;

  t.guide_template = options.guide_template || HandlebarsTemplates.TVGuide;
  t.date_picker_template = options.date_picker_template || HandlebarsTemplates.DatePicker;
  t.upcoming_carousel_template =
    options.upcoming_carousel_template || HandlebarsTemplates.TVGuideUpcomingCarousel;

  t.guide_container = options.guide_container || Spdy('.tv_guide_container');
  t.date_picker_container = options.date_picker_container || Spdy('.date_picker_container');
  t.tvguide_lookup = options.tvguide_lookup;
  t.tv_data_url = options.tv_data_url;
  t.cms_calendar = options.cms_calendar;
  t.primetime = options.primetime;
  t.locale = options.locale || 'en_GB';
  t.date_format = options.date_format || 'DD.MM.YY';
  t.section_data = options.section_data || null;
  t.backup_image = options.backup_image || null;

  t.tv_guide_carousel = Spdy('.upcoming_block');

  t.i18n = {
    previousMonth: 'Previous Month',
    nextMonth: 'Next Month',
    months: [
      t.cms_calendar.month_1,
      t.cms_calendar.month_2,
      t.cms_calendar.month_3,
      t.cms_calendar.month_4,
      t.cms_calendar.month_5,
      t.cms_calendar.month_6,
      t.cms_calendar.month_7,
      t.cms_calendar.month_8,
      t.cms_calendar.month_9,
      t.cms_calendar.month_10,
      t.cms_calendar.month_11,
      t.cms_calendar.month_12,
    ],
    weekdays: [
      t.cms_calendar.day_7_name, //Sunday
      t.cms_calendar.day_1_name, //Monday
      t.cms_calendar.day_2_name, //Tuesday
      t.cms_calendar.day_3_name, //Wednesday
      t.cms_calendar.day_4_name, //Thursday
      t.cms_calendar.day_5_name, //Friday
      t.cms_calendar.day_6_name, //Saturday
    ],
    weekdaysShort: [
      t.cms_calendar.day_7, //S
      t.cms_calendar.day_1, //M
      t.cms_calendar.day_2, //T
      t.cms_calendar.day_3, //W
      t.cms_calendar.day_4, //T
      t.cms_calendar.day_5, //F
      t.cms_calendar.day_6, //S
    ],
  };

  t.onLoadedCallback = options.onLoadedCallback || function () {};

  t.height_per_hour = (options.height_per_hour || 114) / 60; //px and rems....... 76 units / 60 minutes

  t.number_of_blocks = 6; //CANT REALLY CHANGE YET...
  t.hour_offset = 6;
  t.tv_data;

  t.currentData;
  t.currentDate;
  t.currentSanitisedData;
  t.currentIndex = 0;

  //HOW MANY REMS/PIXELS IN HEIGHT PER MINUTE
  t.minutes_per_block = (24 / t.number_of_blocks) * 60;

  //Get todays date at 00:00 time used for comparison for dates
  t.todays_date = new moment().hour(0).minute(0).second(0).millisecond(0);

  //Set moment.js to use current locale!
  moment.locale(t.locale);

  t.site_container = Spdy('.container');
  t.tooltip = Spdy('.guide_tooltip');
  t.tooltip_arrow = Spdy('.guide_tooltip .arrow');

  Spdy(window).on('wheel', function (e) {
    t.tooltip.removeClass('active');
  });

  t.once = false;

  Handlebars.registerHelper('math', function (lvalue, operator, rvalue, options) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    return {
      '+': lvalue + rvalue,
      '-': lvalue - rvalue,
      '*': lvalue * rvalue,
      '/': lvalue / rvalue,
      '%': lvalue % rvalue,
    }[operator];
  });

  t.tooltipTimer = 0;
  t.tooltipTouchClose = false;

  t.init();

  return t;
};

TVGuideController.prototype = {
  init: function () {
    let t = this;
    $.ajax({
      url: t.tv_data_url,
      cache: false,
      dataType: 'json',
      success: function (data) {
        t.tv_data = data;
        t.onTVGuideDataLoaded();
      },
    });

    return t;
  },

  openTooltip: function (target) {
    let t = this;
    let link_data = t.tvguide_lookup[Spdy(target).data('link')];
    t.tooltipTouchClose = false;
    clearTimeout(t.tooltipTimer);
    t.tooltipTimer = setTimeout(function () {
      t.tooltipTouchClose = true;
    }, 500);

    t.tooltip.removeClass('reverse');
    t.tooltip.css('top', Spdy(target).offset().top + 'px');
    t.tooltip.css(
      'left',
      Spdy(target).offset().left -
        t.site_container.offset().left +
        t.tooltip_arrow.outerWidth() +
        'px'
    );
    let titleTxt = Spdy(Spdy(target).parent()).find('.name').text();
    t.tooltip.find('.title').text(titleTxt);

    if (link_data) {
      let img_src = null;
      if (link_data.data.tvguide_image !== '') {
        img_src = link_data.data.tvguide_image;
      } else if (link_data.data.image_wide !== '') {
        img_src = link_data.data.image_wide;
      }
      if (img_src) {
        t.tooltip.find('.image img').attr('src', TURNERENTS.dynamic_url + img_src);
        t.tooltip.find('.image').attr('href', link_data._url);
        t.tooltip.find('.image').removeClass('hide');
      } else {
        t.tooltip.find('.image').addClass('hide');
      }

      t.tooltip.find('.btn').attr('href', link_data._url);
      t.tooltip.find('.cal_btn').off('click');
      t.tooltip.find('.cal_btn').on('click', function (e) {
        Spdy.preventDefault(e);
        Spdy('#ical_downloaderform')
          .find('[name="start"]')
          .attr('value', Spdy(target).data('start'));
        Spdy('#ical_downloaderform').find('[name="end"]').attr('value', Spdy(target).data('end'));
        Spdy('#ical_downloaderform')
          .find('[name="title"]')
          .attr('value', t.tooltip.find('.title').text());
        Spdy('#ical_downloaderform').elements[0].submit();
        /*$.ajax({
                        type:"post",
                        data:{
                            start:Spdy(target).data('start'),
                            end:Spdy(target).data('end'),
                            title:t.tooltip.find('.title').text()
                        },
                        url:TURNERENTS.TVGUIDE_DATA.friendly_url+'download_ics',
                        success: function(d){
                            console.log(d);
                        }
                    });*/
      });

      t.tooltip.find('.btn').removeClass('hide');
    } else {
      t.tooltip.find('.image').addClass('hide');
      t.tooltip.find('.btn').addClass('hide');
      t.tooltip.find('.synopsis').addClass('hide');
      t.tooltip.find('.time').text(Spdy(Spdy(target).parent()).find('.time').text());
    }

    let episodeTitleTxt = Spdy(target).data('episodetitle') || '';
    let episodeNumber = Spdy(target).data('episode') || '';
    // console.log('episodeTitleTxt',episodeTitleTxt,' | episodeNumber',episodeNumber);
    t.tooltip.find('.episode_title').text('');
    if (Spdy(target).data('season') !== '') {
      t.tooltip
        .find('.episode_title')
        .text(
          TURNERENTS.FEATURE_LOCALISATION.season +
            ' ' +
            Spdy(target).data('season') +
            ' - ' +
            TURNERENTS.FEATURE_LOCALISATION.episode +
            ' ' +
            Spdy(target).data('episode')
        );
    } else if (episodeTitleTxt !== '') {
      if (episodeNumber !== '') {
        episodeTitleTxt = episodeNumber + '. ' + episodeTitleTxt;
      }
      t.tooltip.find('.episode_title').text(episodeTitleTxt);
    }
    // if (Spdy(target).data('season') != "") {
    //     t.tooltip.find('.time').text(TURNERENTS.FEATURE_LOCALISATION.season + " " + Spdy(target).data('season') + " - " + TURNERENTS.FEATURE_LOCALISATION.episode + " " + Spdy(target).data('episode'));
    // } else {
    //     t.tooltip.find('.time').text(Spdy(Spdy(target).parent()).find('.time').text());
    t.tooltip.find('.time').text(Spdy(Spdy(target).parent()).find('.time').text());

    let certification = Spdy(target).data('certification');
    if (certification !== '') {
      t.tooltip.find('.certification').addClass('cert_' + certification);
    }

    if (Spdy(target).data('episode_synopsis') != '') {
      t.tooltip.find('.synopsis').removeClass('hide');
      t.tooltip
        .find('.synopsis_copy')
        .text(decodeURIComponent(Spdy(target).data('episode_synopsis')));
    } else {
      t.tooltip.find('.synopsis').addClass('hide');
    }

    //FLIP TOOLTIP ARROW SO IF IT DOESNT HAVE ENOUGH ROON AT THE BOTTOM OF THE PAGE
    if (
      t.guide_container.outerHeight() + t.guide_container.offset().top <
      t.tooltip.outerHeight() + Spdy(target).offset().top
    ) {
      t.tooltip.addClass('reverse');
      t.tooltip.css(
        'top',
        Spdy(target).offset().top - t.tooltip.outerHeight() + t.tooltip_arrow.outerHeight() + 'px'
      );
    }

    t.tooltip.addClass('active');
  },

  onTVGuideDataLoaded: function () {
    let t = this;
    //Finish array TV data conversion
    // console.log(t.tv_data);

    t.tv_data = t.parseTVGuideData(t.tv_data);

    //Get correct days data from t.tv_data array
    //IF TODAYS DATE ISNT IN THE t.tv_data THEN SHOW THE FIRST DAYS DATA BY DEFAULT FIRST
    if (!t.todaysIndex) {
      //t.currentIndex = t.lastIndex;
      //t.setDate(t.lastIndex);
      t.currentIndex = 0;
      t.setDate(0);
    } else {
      //TODAYS DATE WAS IN THE t.tv_data BY t.parseTVGuideData, USE IT!
      t.currentIndex = t.todaysIndex;
      t.setDate(t.todaysIndex);
    }

    Spdy(document).on('mouseover', function (e) {
      t.onHoverTouchend(e);
    });

    Spdy(document).on('touchend', function (e) {
      t.onHoverTouchend(e);
    });

    Spdy(document).on('click', function (e) {
      if (
        !Spdy(e.target).hasClass('.date_picker') &&
        !Spdy('.date_picker').elements[0].contains(e.target)
      ) {
        Spdy('.pika-single').removeClass('active');
      }
    });

    t.tooltip.on('click', function (e) {
      if (t.tooltipTouchClose) {
        t.tooltip.removeClass('active');
      }
    });
    //render inital date view
    t.render();

    setInterval(function () {
      if (t.isToday(t.currentDate)) {
        t.setCurrentTimeBar();
      }
    }, 60000);
  },

  onHoverTouchend: function (e) {
    let t = this;
    if (Spdy(e.target).data('link')) {
      t.openTooltip(e.target);
    } else if (!t.tooltip.elements[0].contains(e.target)) {
      t.tooltip.removeClass('active');
    }
  },

  parseTVGuideData: function (data) {
    let t = this;
    //LOOP - Calculate duration of show and match todays date with the best day in the data
    //Sort out data so its a bit more usable for JS - convert start end times to moment objects
    let new_tv_data_array = [];
    let count = 0;
    let parsed_date;
    for (let i in data) {
      let dateCorrectionMode = false;
      //PARSE JSON DATA STRING FOR DD.MM.YY
      let isValidDate = moment(i, t.date_format).isValid();
      if (isValidDate) {
        if (i.length > 10) {
          let date_string = i.substr(i.length - 8, 8);
          let parsed_date = moment(date_string, [t.date_format]);
        } else {
          //PARSE JSON DATA STRING FOR DD/MM/YYYY
          parsed_date = moment(i, [t.date_format]);
          t.parsed_date = parsed_date;
        }

        //if todays date == JSON object date, then set currentData to this object!
        if (t.isToday(parsed_date)) {
          t.todaysIndex = count;
        }

        for (let x in data[i]) {
          let next_position = parseInt(x) + 1;
          // console.log('x = ', x, 'next_position', next_position);
          /**
           * TURNERENTS-412 : TV guide failing because some start times were 'xx.xx' causing moment to choke - so converted to 'xx:xx'
           */
          //IS THERE AN END TIME? IF NOT USE THE NEXT ENTRY START TIME AS THIS END TIME!
          if (data[i][x].end_time == '' && next_position < data[i].length) {
            data[i][next_position].start_time = data[i][next_position].start_time.replace('.', ':');
            data[i][x].end_time = moment(data[i][next_position].start_time);
            if (!data[i][x].end_time.isValid()) {
              data[i][x].end_time = parsed_date
                .clone()
                .hours(data[i][next_position].start_time.split(':')[0])
                .minutes(data[i][next_position].start_time.split(':')[1]);
            }
            //IS THERE AN END TIME? BUT THERES NO NEXT ENTRY? USE END OF DAY TIME SPECIFIED IN THE CMS!
          } else if (data[i][x].end_time == '' && next_position >= data[i].length) {
            data[i][x].end_time = parsed_date.clone().hours('23').minutes('60');
          } else {
            //EVERYTHING IS NORMAL - THE end_time PASSED THROUGH IS A NICE FORMAT - YIKES!
            data[i][x].end_time = moment(data[i][x].end_time);
          }
          if (!moment(data[i][x].start_time).isValid()) {
            data[i][x].start_time = parsed_date
              .clone()
              .hours(data[i][x].start_time.split(':')[0])
              .minutes(data[i][x].start_time.split(':')[1]);
          } else {
            data[i][x].start_time = moment(data[i][x].start_time);
          }

          if (dateCorrectionMode) {
            data[i][x].start_time.add(1, 'day');
            data[i][x].end_time.add(1, 'day');
          }

          /**
           * DURATION
           */
          data[i][x].duration =
            (data[i][x].end_time.toDate() - data[i][x].start_time.toDate()) / (1000 * 60);
          if (data[i][x].duration < 0) {
            dateCorrectionMode = true;
            //console.log("dateCorrectionMode activated");
            data[i][x].end_time.add(1, 'day');
            data[i][x].duration =
              (data[i][x].end_time.toDate() - data[i][x].start_time.toDate()) / (1000 * 60);
          }
          data[i][x].duration = Math.round(data[i][x].duration);
        }
        t.lastIndex = count;
        count++;
        //Create new better array out of data
        new_tv_data_array.push({
          date: parsed_date,
          data: t.tv_data[i],
        });
      } else {
        console.warn('Skipping date [', i, '] as it is invalid. Format should be', t.date_format);
      }
    }

    return new_tv_data_array;
  },

  render: function () {
    let t = this;

    t.destroy();

    t.renderGuide();

    t.renderDatePicker();

    t.createButtons();

    t.onLoadedCallback();
  },

  renderGuide: function () {
    let t = this;
    //Get current data sorted into 4 blocks
    t.sanitiseBlock();

    //Update view
    t.guide_container.html(t.guide_template({ data: t.currentSanitisedData }));

    if (t.tv_guide_carousel.length > 0) {
      if (t.isToday(t.currentDate)) {
        t.setCurrentTimeBar();
        if (t.tv_guide_carousel.length > 0) {
          t.setNowLaterBoxes();
        }
      } else {
        t.setBackupImageBox();
      }
    }
  },

  renderDatePicker: function () {
    let t = this;

    let day_month_string = '';
    if (t.cms_calendar.date_format == 'day_number_month') {
      day_month_string =
        t.currentDate.format('D') + ' ' + t.cms_calendar['month_' + t.currentDate.format('M')];
    } else {
      day_month_string =
        t.cms_calendar['month_' + t.currentDate.format('M')] + ' ' + t.currentDate.format('D');
    }

    // console.log('cms_calendar',t.cms_calendar);
    // console.log('cms_calendar.date_format',t.cms_calendar.date_format);
    // console.log('renderDatePicker | day_month_string',day_month_string);

    let day_name_string = '';
    let day_number = parseInt(t.currentDate.format('d'));
    if (day_number == 0) {
      day_name_string = t.cms_calendar['day_7_name'];
    } else {
      day_name_string = t.cms_calendar['day_' + day_number + '_name'];
    }

    t.date_picker_container.html(
      t.date_picker_template({
        day_name: day_name_string,
        day: day_month_string,
      })
    );

    //1== monday , 7 == sunday

    t.picker = new Pikaday({
      firstDay: t.cms_calendar.week_start == '1' ? 1 : 0,
      format: t.date_format,
      i18n: t.i18n,
      defaultDate: t.currentDate.toDate(),
      setDefaultDate: true,
      showDaysInNextAndPreviousMonths: true,
      minDate: t.tv_data[0].date.toDate(),
      maxDate: t.tv_data[t.tv_data.length - 1].date.toDate(),
      onSelect: function (date) {
        t.findDateAndSet(date, function () {
          t.render();
        });
      },
    });
    Spdy('.date_picker').append(t.picker.el);

    Spdy('.js_date_picker').on('click', function () {
      t.date_picker_container.toggleClass('active');
    });
  },

  isToday: function (date_target) {
    let t = this;
    return t.todays_date.toDate().getTime() == date_target.toDate().getTime();
  },

  setCurrentTimeBar: function () {
    let t = this;
    Spdy('.current_time').removeClass('active');
    let current_time = new moment();
    let current_hour = current_time.toDate().getHours();

    //figure out which guide box its in
    let guide_index = Math.floor((current_hour - t.hour_offset) / (24 / t.number_of_blocks));
    if (guide_index < 0) guide_index = t.number_of_blocks - 1;

    //Figure out what the start time is for that box
    let _hour = t.hour_offset + (24 / t.number_of_blocks) * guide_index;
    if (_hour >= 24) {
      _hour -= 24;
    }
    let block_start_time = new moment().hour(_hour).minute(0).second(0);
    //Difference between current time and start of guide box time in minutes
    let diff_mins = current_time.diff(block_start_time) / (1000 * 60);

    Spdy('[data-guide="' + guide_index + '"] .current_time').addClass('active');
    Spdy('[data-guide="' + guide_index + '"] .current_time').css(
      'top',
      diff_mins * t.height_per_hour + 'px'
    );
    Spdy('[data-guide="' + guide_index + '"] .current_time').css(
      'top',
      (diff_mins * t.height_per_hour) / 100 + 'rem'
    );

    //Autoscroll the page to current time ONCE
    if (!t.once) {
      setTimeout(function () {
        window.scrollTo(0, Spdy('.current_time.active').offset().top);
      }, 500);
      t.once = true;
    }
  },

  setNowLaterBoxes: function () {
    let t = this;
    let nowShow = null;
    let nextShow = null;
    let laterShow = null;
    let currentTime = new moment();

    breaksearch: for (let block in t.currentSanitisedData) {
      for (let i in t.currentSanitisedData[block].item) {
        let item_data = t.currentSanitisedData[block].item[i];
        if (currentTime.toDate().getTime() > item_data.start_time.toDate().getTime()) {
          nowShow = item_data;
        } else {
          nextShow = item_data;
          if (t.currentSanitisedData[block].item[parseInt(i) + 1]) {
            laterShow = t.currentSanitisedData[block].item[parseInt(i) + 1];
          } else if (
            t.currentSanitisedData[parseInt(block) + 1] &&
            t.currentSanitisedData[parseInt(block) + 1].item[0]
          ) {
            laterShow = t.currentSanitisedData[parseInt(block) + 1].item[0];
          }
          break breaksearch;
        }
      }
    }

    let templateData = {};
    templateData.blocks = [];
    let linkedData = null;
    if (nowShow) {
      linkedData = t.renderUpcomingBlockSlide(nowShow, t.section_data.now_slide_label);
      if (linkedData != null) {
        templateData.blocks.push(linkedData);
      }
    }
    if (nextShow) {
      linkedData = t.renderUpcomingBlockSlide(nextShow, t.section_data.next_slide_label);
      if (linkedData != null) {
        templateData.blocks.push(linkedData);
      }
    }
    if (laterShow) {
      linkedData = t.renderUpcomingBlockSlide(laterShow, t.section_data.later_slide_label);
      if (linkedData != null) {
        templateData.blocks.push(linkedData);
      }
    }

    if (t.tvUpcomingCarousel) {
      t.tvUpcomingCarousel.destroy();
    }

    t.pushPlaceholderSlide(templateData.blocks);

    t.tv_guide_carousel.find('.carousel_wrapper').html(t.upcoming_carousel_template(templateData));

    t.initTVCarousel();
  },

  setBackupImageBox: function () {
    let t = this;
    let templateData = {};
    templateData.blocks = [];

    t.pushPlaceholderSlide(templateData.blocks);

    t.tv_guide_carousel.find('.carousel_wrapper').html(t.upcoming_carousel_template(templateData));

    t.initTVCarousel();
  },

  pushPlaceholderSlide: function (_targetArray) {
    let t = this;
    if (_targetArray.length == 0 && t.backup_image) {
      let optionalPlaceholderSlide = {};
      optionalPlaceholderSlide.url = t.backup_image.tvguide_backup_link;
      optionalPlaceholderSlide.image = TURNERENTS.dynamic_url + t.backup_image.tvguide_backup_image;
      optionalPlaceholderSlide.image_wide =
        TURNERENTS.dynamic_url + t.backup_image.tvguide_backup_image_large;
      optionalPlaceholderSlide.label = t.backup_image.tvguide_backup_text;
      _targetArray.push(optionalPlaceholderSlide);
    }
  },

  initTVCarousel: function () {
    let t = this;
    t.tvUpcomingCarousel = createCarouselWidget({
      carousel: t.tv_guide_carousel,
      carousel_area: t.tv_guide_carousel.find('.carousel_area'),
      children_wrapper: t.tv_guide_carousel.find('.carousel_wrapper'),
      carousel_children: t.tv_guide_carousel.find('.carousel_item'),
      arrows: true,
      dragging: true,
      autoscroll: true,
      controls_container: t.tv_guide_carousel.find('.controls_container'),
    });
    t.tvUpcomingCarousel.init();
  },

  renderUpcomingBlockSlide: function (_blockData, _label) {
    let t = this;
    let block = {};
    let link_data = t.tvguide_lookup[_blockData.link];

    if (link_data) {
      block.url = link_data._url;
      if (link_data.data.image) {
        block.image = TURNERENTS.dynamic_url + link_data.data.image;
      } else {
        block.image = TURNERENTS.ImageNotFoundV;
      }
      if (link_data.data.image_wide) {
        block.image_wide = TURNERENTS.dynamic_url + link_data.data.image_wide;
      } else {
        block.image_wide = TURNERENTS.ImageNotFoundH;
      }
      block.label = _label;
      return block;
    } else {
      return null;
    }
  },

  //SORT INTO 4 (t.number_of_blocks) BLOCKS
  //CONSTRUCT BETTER ARRAY FOR HANDLEBARS TO WORK WITH
  sanitiseBlock: function () {
    let t = this;
    t.currentSanitisedData = [];
    let total_mins = 0;
    let totalMinsAll = 0;
    let previous_block_offset = 0;

    let tvguide_show_times =
      TURNERENTS.TVGUIDE_DATA.section_data.tvguide_show_times === '0' ? false : true;

    for (let i = 0; i < t.number_of_blocks; i++) {
      t.currentSanitisedData[i] = { item: [], hour: t.getHoursForBlock(i) };
      //IF FIRST SHOW OF THE DAY DOESNT START ON THE HOUR, ADD A BLANK ENTRY TO ADD PADDING.
      //e.g. FIRST SHOW OF THE DAY STARTS AT 1:20, INSTEAD OF 1:00, ADD 20 MINUTES PADDING BLOCK TO BEGINING OF THE DAY
      if (i == 0 && t.currentData[0].start_time.minutes() != 0) {
        total_mins = t.currentData[0].start_time.minutes();
        totalMinsAll = t.currentData[0].start_time.minutes();

        t.currentSanitisedData[i].item.push({
          duration: t.currentData[0].start_time.minutes(),
          title: '',
          start_time: t.currentData[0].start_time,
          end_time: t.currentData[0].end_time,
          time_range: '',
          height_px:
            t.height_per_hour * (t.currentData[0].start_time.minutes() + previous_block_offset),
          height_rem:
            (t.height_per_hour * (t.currentData[0].start_time.minutes() + previous_block_offset)) /
            100,
          duplicate_entry: false,
          link: '',
        });
      }

      while (t.currentData.length > 0) {
        let duplicate_entry = '';
        if (total_mins <= t.minutes_per_block) {
          //CONSTRUCTED DATA SENT TO VIEW

          //If a show overran from the previous block, this is a duplicate entry of that show
          //This sets a duplicate_entry class which is hidden on mobile
          if (previous_block_offset != 0) {
            duplicate_entry = 'duplicate_entry';
          } else {
            totalMinsAll += t.currentData[0].duration;
          }

          //HANDLEBARS DATA - CHANGE THESE REMS TO PADDING-BOTTOM PERCENTAGE SOMEDAY!
          t.currentSanitisedData[i].item.push({
            duration: t.currentData[0].duration,
            title: t.currentData[0].title,
            episode_title: t.currentData[0].episode_title,
            certification: t.currentData[0].certification,
            start_time: t.currentData[0].start_time,
            end_time: t.currentData[0].end_time,
            start_time_pretty: t.currentData[0].start_time.format(),
            end_time_pretty: t.currentData[0].end_time.format(),
            time_range:
              t.currentData[0].start_time.format('HH:mm') +
              ' - ' +
              t.currentData[0].end_time.format('HH:mm'),
            tvguide_show_times: tvguide_show_times,
            height_px: t.height_per_hour * (t.currentData[0].duration + previous_block_offset),
            height_rem:
              (t.height_per_hour * (t.currentData[0].duration + previous_block_offset)) / 100,
            duplicate_entry: duplicate_entry,
            link: t.currentData[0].link,
            season: t.currentData[0].season,
            episode: t.currentData[0].episode,
            episode_synopsis: encodeURIComponent(t.currentData[0].episode_synopsis),
            total_mins: totalMinsAll,
          });
          // let o = t.currentSanitisedData[i].item[t.currentSanitisedData[i].item.length-1]
          // console.log(o.title,' | season',o.season);
          // KEEP TRACK OF HOW MUCH TIME IS LEFT IN THIS BLOCK
          total_mins += t.currentData[0].duration;
          previous_block_offset = 0;

          if (total_mins <= t.minutes_per_block) {
            //IF WE STILL HAVE TIME LEFT - SHIFT ARRAY ITEM AND KEEP GOING!
            t.currentData.shift();
          }
        } else {
          //RAN OUT OF BLOCK TIME! - TAKE OVERLAPPING TIME AND START IN NEW BLOCK - DONT POP CURRENT ITEM SO IT CAN APPEAR IN 2 OVERLAPPING BLOCKS
          total_mins = previous_block_offset =
            total_mins - t.minutes_per_block - t.currentData[0].duration;
          break;
        }
      }
    }
  },

  getHoursForBlock: function (i) {
    let t = this;
    //Starts at t.hour_offset (first show of the day's hour) insteaf of 00 so have to do this stupid hack to sort the hours out
    //Work out if primetime too
    let hours = [];
    for (let z = 0; hours.length < 24 / t.number_of_blocks; z++) {
      let is_primetime = '';
      let _hour = t.hour_offset + z + (24 / t.number_of_blocks) * i;
      if (_hour > 24) {
        _hour -= 24;
      }

      if (_hour >= t.primetime[0] && _hour <= t.primetime[1]) {
        is_primetime = 'primetime_slot';
      }

      _hour = moment(_hour + ':00', ['h:mm']).format('HH');
      hours.push({ digits: _hour, primetime: is_primetime });
    }
    return hours;
  },

  setDate: function (index) {
    let t = this;
    //CLONE DATA SO ORIGINAL IS NOT DESTROYED
    t.currentData = t.tv_data[index].data.slice(0);
    t.currentDate = t.tv_data[index].date;

    t.hour_offset = t.currentData[0].start_time.hours();
  },

  nextDate: function (callback) {
    let t = this;
    t.currentIndex++;
    if (t.currentIndex > t.tv_data.length - 1) {
      t.currentIndex = t.tv_data.length - 1;
    } else {
      t.setDate(t.currentIndex);
      callback();
    }
  },

  prevDate: function (callback) {
    let t = this;
    t.currentIndex--;
    if (t.currentIndex < 0) {
      t.currentIndex = 0;
    } else {
      t.setDate(t.currentIndex);
      callback();
    }
  },

  findDateAndSet: function (target, callback) {
    console.log('findDateAndSet ', target);
    let t = this;
    let momentTarget = new moment(target);
    momentTarget = momentTarget.toDate().getTime();
    let _found = false;
    for (let i in t.tv_data) {
      if (momentTarget == t.tv_data[i].date.toDate().getTime()) {
        t.currentIndex = i;
        _found = true;
      }
    }
    if (_found) {
      t.setDate(t.currentIndex);
      callback();
    }
  },

  destroy: function () {
    let t = this;
    Spdy('.date_picker .nav.left').off('click');
    Spdy('.date_picker .nav.right').off('click');
    Spdy('.date_picker .center_button').off('click');
    Spdy('.js_date_picker').off('click');
    if (t.picker) {
      t.picker.destroy();
    }
  },

  createButtons: function () {
    let t = this;

    Spdy('.date_picker .nav.left').on('click', function (e) {
      t.prevDate(function () {
        t.render();
      });
    });
    Spdy('.date_picker .nav.right').on('click', function (e) {
      t.nextDate(function () {
        t.render();
      });
    });
  },
};

function createTVGuideController(options) {
  return new TVGuideController(options);
}

module.exports = createTVGuideController;
