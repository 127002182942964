const Ellipsity = (function () {
  function Ellipsity() {}

  //Binary search to find optimal splitting points.
  Ellipsity.prototype.binarySearch = function (length, callback) {
    let low = 0;
    let high = length - 1;
    let best = -1;
    let mid;

    while (low <= high) {
      mid = ~~((low + high) / 2);
      let result = callback(mid);
      if (result < 0) {
        high = mid - 1;
      } else if (result > 0) {
        low = mid + 1;
      } else {
        best = mid;
        low = mid + 1;
      }
    }
    return best;
  };

  //Check to see if the text is overflowing.
  Ellipsity.prototype.isTextOverflowing = function (element) {
    let currentOverflow = element.style.overflow;
    if (!currentOverflow || currentOverflow === 'visible') element.style.overflow = 'hidden';

    let isOverflowing =
      element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
    element.style.overflow = currentOverflow;
    return isOverflowing;
  };

  //Ellipsify the container so the last text element inside fits.
  Ellipsity.prototype.ellipsify = function (container) {
    let self = this;
    let content = container.lastElementChild;
    let text = content.innerText;
    if (this.isTextOverflowing(container)) {
      let checkFunc = function (i) {
        content.innerText = text.substring(0, i);
        return self.isTextOverflowing(container) ? -1 : 0;
      };
      let len = this.binarySearch(text.length - 1, checkFunc);
      content.innerText = text.substring(0, len).slice(0, -3) + '...';
    }
  };

  return new Ellipsity();
})();

function createEllipsity(options) {
  return Ellipsity;
}

module.exports = createEllipsity;
