export const getContentData = () => {
  const content = TURNERENTS.pageContentData || {};
  const { title = '', game_category = '', name_english = 'nvs', show = {} } = content;
  const { name_english: show_name_english = 'nvs' } = show;
  const gameCategory = game_category ? game_category.toLowerCase() : '';
  return {
    title: title.toLowerCase(),
    nameEnglish: name_english ? name_english.toLowerCase() : 'nvs',
    showNameEnglish: show_name_english ? show_name_english.toLowerCase() : '',
    gameCategory,
  };
};
