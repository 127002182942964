var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"column\">\n    <div class=\"programme w400 tall\" data-genre=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"genre") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"is_redirect") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"overlay_active") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":38,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n\n</li>";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"redirect_url") : stack1), depth0))
    + "\" class=\"item\" target=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"redirect_target") : stack1), depth0))
    + "\" data-externalfeatureid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":13},"end":{"line":9,"column":20}}})) != null ? stack1 : "")
    + "            </a>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            		<div class=\"cta_image\" style=\"background-image:url('"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dynamic_url") : depths[1]), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "');\"></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                	<div class=\"cta_image\" style=\"background-image:url('"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"image_not_found") : depths[1]), depth0))
    + "');\"></div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"friendly_url") : depths[1]), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"friendly_url") : depth0), depth0))
    + "\" class=\"item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":17,"column":20}}})) != null ? stack1 : "")
    + "            </a>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        		    <div class=\"cta_image\" style=\"background-image:url('"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dynamic_url") : depths[1]), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "');\"></div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"overlay theme_bg_img\">\n			<div class=\"name\"><a href=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"friendly_url") : depths[1]), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"friendly_url") : depth0), depth0))
    + "\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"title_text") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div>\n			<div class=\"arrow up icon icon_arrow_up\"></div>\n			<div class=\"arrow down icon icon_arrow_down\"></div>\n			<div class=\"info t_mce\">\n			"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"body_text") : stack1), depth0)) != null ? stack1 : "")
    + "\n			</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"video_active") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":3},"end":{"line":36,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"video\">\n				<div class=\"media\" data-videoid=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"videoData") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-videotype=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"videoData") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" data-videoname=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"videoData") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n					<div class=\"play\"><img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"static_url") : depths[1]), depth0))
    + "site/images/icon_play_up.png\" alt=\"\" class=\"up\"><img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"static_url") : depths[1]), depth0))
    + "site/images/icon_play_over.png\" alt=\"\" class=\"over\"></div>\n					<img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dynamic_url") : depths[1]), depth0))
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"videoData") : stack1)) != null ? lookupProperty(stack1,"thumbnail") : stack1), depth0))
    + "\" alt=\"\">\n				</div><div class=\"copy t_mce\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"videoData") : stack1)) != null ? lookupProperty(stack1,"text") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n			</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":14}}})) != null ? stack1 : "")
    + "\n";
},"useData":true,"useDepths":true});