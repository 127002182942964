const Spdy = require('./Speedy');
const createShareController = require('modules/ShareController');

let GenericPopupContorller = function (options) {
  let t = this;
  t.popup = Spdy('.popup.generic');
  t.close_button = t.popup.find('.js_popup_close');
  t.copy = options.copy;
  t.init();

  return t;
};

GenericPopupContorller.prototype = {
  init: function () {
    let t = this;

    t.create();

    return t;
  },

  create: function () {
    let t = this;

    //Popup overlay events

    Spdy('.js_open_generic_popup').on('click', function (e) {
      e.preventDefault();
      t.onPopupOpen(this);
    });

    t.close_button.on('click', function (e) {
      Spdy.preventDefault(e);
      t.onPopupClose();
    });

    t.popup.on('click', function (e) {
      if (
        !(
          t.popup.find('.scrollbox').elements[0].contains(e.target) ||
          Spdy(e.target).hasClass('scrollbox')
        )
      ) {
        t.onPopupClose();
      }
    });
  },

  onPopupOpen: function (item) {
    let t = this;

    t.popup.addClass('active');

    //Popuplate popup scroller with correct content
    Spdy('.popup.generic .scroll_container').html(
      t.copy.links[parseInt(Spdy(item).data('index'))].body_text
    );
    Spdy('.popup.generic .popup_title').html(
      t.copy.links[parseInt(Spdy(item).data('index'))].title_text
    );

    window.scrollTo(0, 0);
    if (Spdy(item).data('trigger')) {
      try {
        if (history.pushState) {
          history.pushState(null, null, '#' + Spdy(item).data('trigger'));
        } else {
          location.hash = '#' + Spdy(item).data('trigger');
        }
      } catch (e) {}
    }
  },

  onPopupClose: function () {
    let t = this;
    t.popup.removeClass('active');
    try {
      if (history.pushState) {
        history.pushState(null, null, '#');
      } else {
        location.hash = '#';
      }
    } catch (e) {}
  },

  destroy: function () {
    let t = this;
    Spdy('.js_open_generic_popup').off('click');
    t.close_button.off('click');
  },
};

function createGenericPopupContorller(options) {
  return new GenericPopupContorller(options);
}

module.exports = createGenericPopupContorller;
