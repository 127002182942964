var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"programme w400 short catchup-block \" data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":74}}}) : helper)))
    + "\" data-uvid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uvid") || (depth0 != null ? lookupProperty(depth0,"uvid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uvid","hash":{},"data":data,"loc":{"start":{"line":1,"column":87},"end":{"line":1,"column":97}}}) : helper)))
    + "\">\n    <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":25}}}) : helper)))
    + "\">\n    <div class=\"overlay theme_bg_clr2 active\">\n        <div class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":37}}}) : helper)))
    + "</div>\n        <div class=\"info\">Series "
    + alias4(((helper = (helper = lookupProperty(helpers,"series") || (depth0 != null ? lookupProperty(depth0,"series") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"series","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":45}}}) : helper)))
    + " Episode "
    + alias4(((helper = (helper = lookupProperty(helpers,"episode") || (depth0 != null ? lookupProperty(depth0,"episode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"episode","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":67}}}) : helper)))
    + "<br>"
    + alias4(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":5,"column":81}}}) : helper)))
    + " | "
    + alias4(((helper = (helper = lookupProperty(helpers,"duration") || (depth0 != null ? lookupProperty(depth0,"duration") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"duration","hash":{},"data":data,"loc":{"start":{"line":5,"column":84},"end":{"line":5,"column":98}}}) : helper)))
    + "min | "
    + alias4(((helper = (helper = lookupProperty(helpers,"remaining") || (depth0 != null ? lookupProperty(depth0,"remaining") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remaining","hash":{},"data":data,"loc":{"start":{"line":5,"column":104},"end":{"line":5,"column":119}}}) : helper)))
    + "</div>\n    </div>\n</div>";
},"useData":true});