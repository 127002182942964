import {
  AD_TEMPLATE,
  getTrackingArray,
  TURNER_METADATA,
} from 'modules/ensighten_tracking/constants/ensightenConstants';
import { getContentData } from 'modules/ensighten_tracking/utils';

const generateAdID = () => {
  const randamNumber = (length) => {
    return Math.floor(Math.random() * length);
  };
  // Generate a random Ad ID to insert into the tracking data
  const generateAdID = () => {
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(randamNumber(possible.length));
    }
    return text;
  };
  return generateAdID();
};

const _track = ({ type, subtype, data }) => {
  const tracking = getTrackingArray();
  // console.log('$$$$$$$$$ track', type, subtype, data);
  tracking.push({ type, subtype, data });
};

export const trackVideoReady = ({ duration }) => {
  const { title, nameEnglish, showNameEnglish } = getContentData();
  const { pageviewevent, feedcode, previouspage, screenorientation, ...baseData } =
    window.dataLayer;
  baseData.content_name = title;
  baseData.content_duration = duration;
  baseData.content_id = generateAdID();
  baseData.content_type = 'clip';
  baseData.content_showName = showNameEnglish;
  // baseData.videotitle = title;
  // baseData.englishname = nameEnglish;
  _track({ type: 'video', subtype: 'Player_Ready', data: baseData });
};

export const trackAdStarted = ({ duration }) => {
  let adData = { ...AD_TEMPLATE };
  adData.ad_id = generateAdID();
  adData.ad_duration = duration;
  _track({ type: 'video', subtype: 'Ad_Started', data: adData });
};

export const trackAdSkipped = ({}) => {
  _track({ type: 'video', subtype: 'Ad_Skipped', data: {} });
  _track({ type: 'video', subtype: 'Media_Started', data: {} });
};

export const trackAdFinished = ({}) => {
  _track({ type: 'video', subtype: 'Ad_Finished', data: {} });
  _track({ type: 'video', subtype: 'Media_Started', data: {} });
};

export const trackPlaybackFinished = ({}) => {
  _track({ type: 'video', subtype: 'Media_Finished', data: {} });
  _track({ type: 'video', subtype: 'Content_Completed', data: {} });
};

export const trackFirstPlay = ({}) => {
  _track({ type: 'video', subtype: 'Media_Started', data: {} });
};

export const trackPlay = ({}) => {
  _track({ type: 'video', subtype: 'Media_Resumed', data: {} });
};

export const trackPaused = ({}) => {
  _track({ type: 'video', subtype: 'Media_Paused', data: {} });
};

export const trackTimeChanged = ({ time }) => {
  TURNER_METADATA && (TURNER_METADATA.currentTime = Math.ceil(time));
};

export const trackStallStarted = ({}) => {
  _track({ type: 'video', subtype: 'Media_Buffering_Started', data: {} });
};

export const trackStallEnded = ({}) => {
  _track({ type: 'video', subtype: 'Media_Buffering_Finished', data: {} });
};
