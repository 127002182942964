export const oneTrustIsActive = () => {
  const cookiePolicy = window?.TURNERENTS?.COOKIE_POLICY ?? {};
  return cookiePolicy?.oneTrustActive ?? false;
};

export const trackingIsEnabled = () => {
  const cookiePolicy = window?.TURNERENTS?.COOKIE_POLICY ?? {};
  const oneTrustActive = cookiePolicy?.oneTrustActive ?? false;
  const performanceCookiesEnabled = cookiePolicy?.performanceCookiesEnabled ?? false;
  let isEnabled = true; // default to tracking enabled
  if (oneTrustActive) {
    isEnabled = performanceCookiesEnabled;
  }
  return isEnabled;
};

export const functionalCookiesIsEnabled = () => {
  const cookiePolicy = window?.TURNERENTS?.COOKIE_POLICY ?? {};
  const oneTrustActive = cookiePolicy?.oneTrustActive ?? false;
  const functionalCookiesEnabled = cookiePolicy?.functionalCookiesEnabled ?? false;
  let isEnabled = true; // default to tracking enabled
  if (oneTrustActive) {
    isEnabled = functionalCookiesEnabled;
  }
  return isEnabled;
};
