import BitmovinPlayer from 'modules/video/BitmovinPlayer';
import YoutubePlayer from 'modules/video/YoutubePlayer';
import { getLogOptions } from 'constants/logging';

let VideoPopupController = function (options) {
  this.popup = Spdy('.popup.video');
  this.youtube = Spdy('.popup.video .youtube');
  this.bitmovin = Spdy('.popup.video .bitmovin');
  this.close_button = this.popup.find('.js_popup_close');
  this.video_container = this.popup.find('.video_container');

  this.log = getLogOptions({ id: 'video' });

  this.init();

  return this;
};

VideoPopupController.prototype = {
  init: function () {
    this.close_button.on('click', (e) => {
      Spdy.preventDefault(e);
      this.onPopupClose();
    });

    // TODO : clicking fade isnt closing properly now - leaving for expediency - hopefully Ash can fix this
    // this.popup.on('click', (e) => {
    //   if (
    //     !(
    //       this.popup.find('.video_wrapper').elements[0].contains(e.target) ||
    //       Spdy(e.target).hasClass('video_wrapper')
    //     )
    //   ) {
    //     this.onPopupClose();
    //   }
    // });

    return this;
  },

  youTubeApiReady: function () {
    if (this.youTubePlayer) {
      this.log.enabled &&
        console.log(`${this.log.prep} VideoPopupController.youTubeApiReady`, this.log.clr);
      this.youTubePlayer.setupPlayer();
    }
  },

  openVideo: function ({ type, id, tracking_name }) {
    Spdy('.popup').removeClass('active');
    this.popup.addClass('active');
    window.scrollTo(0, 0);
    const autoplay = window.TURNERENTS.video_autoplay;

    switch (type) {
      case 'youtube':
        this.createYTVideo(id, tracking_name, autoplay);
        break;
      case 'avs':
        this.createAVSVideo(id, tracking_name, autoplay);
        break;
    }
  },

  createYTVideo: function (id, tracking_name, autoplay) {
    this.bitmovin.hide();
    this.youtube.show();
    this.avsPlayer && this.avsPlayer.stopVideo();

    if (this.youTubePlayer) {
      this.youTubePlayer.playVideo({ id, tracking_name, autoplay });
    } else {
      this.log.enabled && console.log(`${this.log.prep} createYTVideo ${id}`, this.log.clr);
      this.youTubePlayer = new YoutubePlayer({
        id,
        tracking_name,
        domElementId: 'youtube-player-popup',
        autoplay,
      });
    }
  },

  createAVSVideo: function (id, tracking_name, autoplay) {
    this.youtube.hide();
    this.bitmovin.show();
    this.youTubePlayer && this.youTubePlayer.stopVideo();

    const { avsData } = window.TURNERENTS;
    avsData.video_id = id && id !== '' ? id : '1000105130';
    avsData.staticUrl = window.TURNERENTS.static_url;
    avsData.autoplay = autoplay;

    if (this.avsPlayer) {
      this.avsPlayer.playVideo({ avsData });
    } else {
      this.log.enabled && console.log(`${this.log.prep} createAVSVideo ${id}`, this.log.clr);
      this.avsPlayer = new BitmovinPlayer({ avsData, domElementId: 'bitmovin-player-popup' });
    }
  },

  onPopupClose: function () {
    this.popup.removeClass('active');
    this.destroy();
  },

  destroy: function () {
    this.avsPlayer && this.avsPlayer.stopVideo();
    this.youTubePlayer && this.youTubePlayer.stopVideo();
  },
};

function createVideoPopupController(options) {
  return new VideoPopupController(options);
}

module.exports = createVideoPopupController;
