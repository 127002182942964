//For use with ClienwiseXMLParser - needs decoupling of copy

let ShareController = function (options) {
  let t = this;
  t.twitter_copy = options.twitter_copy || Spdy('[property="og:title"]').attr('content');
  t.linkedin_title = options.linkedin_title || Spdy('[property="og:title"]').attr('content');
  t.linkedin_copy = options.linkedin_copy || Spdy('[property="og:description"]').attr('content');
  t.autosetup = true;
  if (options.autosetup != null) {
    t.autosetup = options.autosetup;
  }
  t.device = options.device;
  t.share_buttons = options.share_buttons || Spdy('[data-share]');
  t.popupDimensions = {
    height: 350,
    width: 575,
    top: screen.height / 2 - 350 / 2,
    left: screen.width / 2 - 575 / 2,
  };

  t.init();

  return t;
};

ShareController.prototype = {
  init: function () {
    let t = this;

    t.share_buttons.each(function (index, item) {
      switch (Spdy(item).data('share')) {
        case 'facebook':
          Spdy(item).on('click', function (e) {
            Spdy.preventDefault(e);
            t.facebookShare();
          });
          break;

        case 'twitter':
          Spdy(item).on('click', function (e) {
            Spdy.preventDefault(e);
            t.twitterShare(t.twitter_copy + encodeURIComponent(' ' + document.URL));
          });

          break;

        case 'linkedin':
          Spdy(item).on('click', function (e) {
            Spdy.preventDefault(e);
            t.linkedinShare();
          });
          break;
      }
    });

    return t;
  },

  facebookShare: function () {
    let t = this;

    if (t.device == 'smartphone' || t.device == 'mobile') {
      t.openWindow(
        'http://m.facebook.com/sharer.php?s=100&p[url]=' + encodeURIComponent(document.URL)
      );
    } else {
      t.openWindow(
        'http://www.facebook.com/sharer.php?s=100&p[url]=' + encodeURIComponent(document.URL)
      );
    }
  },

  twitterShare: function (copy) {
    let t = this;
    t.openWindow('https://twitter.com/intent/tweet?text=' + copy);
  },

  linkedinShare: function () {
    let t = this;

    t.openWindow(
      'http://www.linkedin.com/shareArticle?mini=true&url=' +
        encodeURIComponent(' ' + document.URL) +
        '&source=' +
        encodeURIComponent(' ' + document.URL) +
        '&title=' +
        t.linkedin_title +
        '&summary=' +
        t.linkedin_copy
    );
  },

  openWindow: function (url) {
    let t = this;

    window.open(
      url,
      'sharer',
      'top=' +
        t.popupDimensions.top +
        ',left=' +
        t.popupDimensions.left +
        ',toolbar=0,status=0,width=' +
        t.popupDimensions.width +
        ',height=' +
        t.popupDimensions.height
    );
  },
};

function createShareController(options) {
  return new ShareController(options);
}

module.exports = createShareController;
