import { triggerTracking } from 'modules/ensighten_tracking/EnsightenTracking';

let CarouselWidget = function (options) {
  let t = this;
  t.options = options;
  t.carousel = t.options.carousel;
  t.carousel_area = t.options.carousel_area;
  t.children_wrapper = t.options.children_wrapper;
  t.carousel_children = t.options.carousel_children;

  t.nav_callback = t.options.nav_callback || function () {};
  t.nav_callback_params = t.options.nav_callback_params;
  t.controls_container = t.options.controls_container || null;

  t.visible_count = options.visible_count || 1;

  // Ash - Added extra options to center the active item
  // whilst still able to see the prev and next images
  // See 'Film Carousel' on a specials film feature page
  t.center_mode = t.options.center_mode || false;
  t.clone_items = t.options.clone_items || false;

  t.calculateWidths();
  // t.children_wrapper.css('height', '394px');

  t.arrows = t.options.arrows || false;
  t.arrowContentLeft = t.options.arrowContentLeft || '';
  t.arrowContentRight = t.options.arrowContentRight || '';
  t.arrows = t.options.arrows || false;
  t.blips_enabled = t.options.blips_enabled || false;

  t.autoscroll = t.options.autoscroll || false;
  t.autoScrollingTimer = 0;
  t.autoscroll_interval = t.options.autoscroll_interval || 4000;
  t.interaction_interupt = false;

  t.dragging = t.options.dragging || false;

  if (options.nav_left != null) {
    t.nav_left = options.nav_left;
    t.nav_left.on('click', function () {
      t.prev();
    });
  }

  if (options.nav_right != null) {
    t.nav_right = options.nav_right;
    t.nav_right.on('click', function () {
      t.next();
    });
  }

  t.children_wrapper.on('click', function (e) {
    t.carouselClick(e);
  });

  t.current_pos = 0;

  //t.init();

  return t;
};

CarouselWidget.prototype = {
  init: function () {
    let t = this;
    t.create();

    return t;
  },

  calculateWidths: function () {
    let t = this;

    if (t.clone_items) {
      let cloneLast = Spdy(t.carousel_children.elements[t.carousel_children.length - 1]).clone();
      Spdy(cloneLast).insertBefore(t.carousel_children.elements[0]);

      let cloneFirst = Spdy(t.carousel_children.elements[0]).clone();
      Spdy(cloneFirst).insertAfter(t.carousel_children.elements[t.carousel_children.length - 1]);
      t.carousel_children = Spdy(t.carousel.elements['0']).find('.carousel_item');
    }

    t.children_wrapper.css('width', (t.carousel_children.length * 100) / t.visible_count + '%');
    if (t.center_mode) {
      if (!t.clone_items) {
        t.children_wrapper.css('left', 25 / 2 + '%');
      }
      t.carousel_children.css('width', 75 / t.carousel_children.length + '%');
    } else {
      t.children_wrapper.css('left', '0%');
      t.carousel_children.css('width', 100 / t.carousel_children.length + '%');
    }
  },

  create: function () {
    let t = this;

    if (t.carousel_children.length - 1 < t.visible_count) {
      t.arrows = false;
      t.blips_enabled = false;
      t.dragging = false;
    }
    t.carousel_children.each(function (index, item) {
      Spdy(item).data('index', index);
    });
    if (t.arrows) {
      t.arrow_left = new SpdyObj([document.createElement('div')]);
      t.arrow_left.addClass('arrow').addClass('left');
      t.arrow_left.html(t.arrowContentLeft);
      if (t.controls_container != null) {
        t.controls_container.append(t.arrow_left.elements[0]);
      } else {
        t.carousel.append(t.arrow_left.elements[0]);
      }
      t.arrow_left.on('click', function () {
        t.prev();
        t.interaction_interupt = true;
      });
    }

    if (t.blips_enabled) {
      t.blip_container = new SpdyObj([document.createElement('div')]);
      t.blip_container.addClass('blips');
      if (t.controls_container != null) {
        t.blip_container = t.controls_container;
      } else {
        t.carousel.append(t.blip_container.elements[0]);
      }

      t.carousel_children.each(function (index, item) {
        t.new_blip = new SpdyObj([document.createElement('div')]);
        t.new_blip.addClass('blip').data('blip', index);
        t.blip_container.append(t.new_blip.elements[0]);
        t.new_blip.on('click', function () {
          t.go_to(Spdy(this).data('blip'));
          t.interaction_interupt = true;
        });
      });
      t.blips = t.blip_container.find('.blip');
    }

    if (t.arrows) {
      t.arrow_right = new SpdyObj([document.createElement('div')]);
      t.arrow_right.addClass('arrow').addClass('right');
      t.arrow_right.html(t.arrowContentRight);
      if (t.controls_container != null) {
        t.controls_container.append(t.arrow_right.elements[0]);
      } else {
        t.carousel.append(t.arrow_right.elements[0]);
      }

      t.arrow_right.on('click', function () {
        t.next();
        t.interaction_interupt = true;
      });
    }

    if (t.clone_items) {
      t.go_to(1);
    }

    if (t.dragging) {
      t.enableDragging();
    }

    if (Spdy('html').attr('dir') == 'rtl') {
      t.prev();
    } else {
      t.update_ui();
    }

    if (t.autoscroll) {
      t.autoScrollingTimer = setTimeout(function () {
        t.autoscroll_init();
      }, t.autoscroll_interval);
    }
  },

  enableDragging: function () {
    let t = this;
    t.children_wrapper.on('touchstart', function (e) {
      let pos = Spdy(this).position();
      t.drag_offset = {
        x: e.changedTouches[0].pageX - pos.left,
        y: e.changedTouches[0].pageY - pos.top,
      };

      t.drag_start = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY,
      };

      t.children_wrapper.addClass('no_transition');
      t.interaction_interupt = true;
    });

    t.children_wrapper.on('touchmove', function (e) {
      if (Math.abs(t.drag_start.x - e.changedTouches[0].pageX) > 10) {
        Spdy.preventDefault(e);
      }

      if (e.changedTouches[0].pageX - t.drag_offset.x > 0) {
        Spdy(this).css('left', '0px');
      } else if (
        e.changedTouches[0].pageX - t.drag_offset.x <
        (Spdy(this).outerWidth() -
          (Spdy(this).outerWidth() / t.carousel_children.length) * t.visible_count) *
          -1
      ) {
        Spdy(this).css(
          'left',
          (Spdy(this).outerWidth() -
            (Spdy(this).outerWidth() / t.carousel_children.length) * t.visible_count) *
            -1 +
            'px'
        );
      } else {
        Spdy(this).css('left', e.changedTouches[0].pageX - t.drag_offset.x + 'px');
      }
    });

    t.children_wrapper.on('touchend', function (e) {
      t.children_wrapper.removeClass('no_transition');
      t.pixelsToPosition();
    });
  },

  autoscroll_init: function () {
    let t = this;
    if (t.autoscroll && t.interaction_interupt == false) {
      t.next(true);
      try {
        clearTimeout(t.autoScrollingTimer);
      } catch (e) {}
      t.autoScrollingTimer = setTimeout(function () {
        t.autoscroll_init();
      }, t.autoscroll_interval);
    }
  },

  pixelsToPosition: function () {
    let t = this;
    t.go_to(
      t.roundNearest(
        t.carousel_children.length *
          (t.children_wrapper.position().left / t.children_wrapper.outerWidth()),
        1
      )
    );
  },

  roundNearest: function (num, acc) {
    if (acc < 0) {
      return Math.round(num * acc) / acc;
    } else {
      return Math.round(num / acc) * acc;
    }
  },

  destroy: function () {
    let t = this;
    try {
      clearTimeout(t.autoScrollingTimer);
    } catch (e) {}
    if (t.nav_left != null) {
      t.nav_left.off('click');
    }
    if (t.nav_right != null) {
      t.nav_right.off('click');
    }
    if (t.arrow_left) {
      t.arrow_left.off('click');
    }
    if (Spdy('.blip').length > 0) {
      Spdy('.blip').off('click');
    }
    if (t.arrow_right) {
      t.arrow_right.off('click');
    }

    t.children_wrapper.off('click');

    try {
      t.children_wrapper.off('touchstart');
      t.children_wrapper.off('touchmove');
      t.children_wrapper.off('touchend');
    } catch (e) {}

    try {
      t.arrow_left.remove();
      t.arrow_right.remove();
      t.blip_container.remove();
    } catch (e) {}
  },

  carouselClick: function (e) {
    let featuredcontent = 'nvs';
    this.carousel_children.each(function (index, item) {
      if (Spdy(item).hasClass('active')) {
        featuredcontent = Spdy(item).data('title');
      }
    });
    triggerTracking('interaction-homepage', {
      englishname: 'nvs', // todo : add englishname
      featuredcontent,
      interaction: 'featured content click',
      interactionevent: 1,
    });
  },

  next: function (autoScroll = false) {
    let t = this;
    t.current_pos++;
    if (t.current_pos > t.carousel_children.length - 1) {
      t.current_pos = 0;
    }

    if (t.current_pos > t.carousel_children.length - t.visible_count) {
      t.current_pos = 0;
    }

    if (t.clone_items && t.current_pos == t.carousel_children.length - 1) {
      t.go_to(1);
    }
    if (!autoScroll) {
      triggerTracking('interaction', {
        interaction: 'carousel next content click',
        interactionevent: 1,
      });
    }
    t.render();
  },

  prev: function () {
    let t = this;
    t.current_pos--;
    if (t.current_pos < 0) {
      t.current_pos = t.carousel_children.length - 1;
    }
    if (t.current_pos > t.carousel_children.length - t.visible_count) {
      t.current_pos = t.carousel_children.length - t.visible_count;
    }
    if (t.clone_items && t.current_pos == 0) {
      t.go_to(t.carousel_children.length - 2);
    }
    triggerTracking('interaction', {
      interaction: 'carousel previous content click',
      interactionevent: 1,
    });
    t.render();
  },

  go_to: function (pos) {
    let t = this;
    pos = Math.abs(pos);
    t.current_pos = parseInt(pos);

    if (t.current_pos > t.carousel_children.length - 1) {
      t.current_pos = t.carousel_children.length - 1;
    }

    if (t.current_pos < 0) {
      t.current_pos = 0;
    }

    if (t.current_pos > t.carousel_children.length - t.visible_count) {
      t.current_pos = t.carousel_children.length - t.visible_count;
    }

    t.render();
  },

  render: function () {
    let t = this;

    // if (t.current_pos = 0) {
    //   console.log(t.current_pos);
    //   if(t.clone_items) {
    //     t.go_to(1);
    //   }
    // }
    if (t.center_mode) {
      t.children_wrapper.css('left', (t.current_pos * -75) / t.visible_count + 25 / 2 + '%');
    } else {
      t.children_wrapper.css('left', (t.current_pos * -100) / t.visible_count + '%');
    }
    t.nav_callback(t.current_pos, t.nav_callback_params);
    t.update_ui();
  },

  update_ui: function () {
    let t = this;
    if (t.blips_enabled) {
      t.blips.removeClass('active');
      t.blips.each(function (index, item) {
        if (t.current_pos == Spdy(item).data('blip')) {
          Spdy(item).addClass('active');
        }
      });
    }
    if (t.visible_count == 1) {
      t.carousel_children.removeClass('active');
      t.carousel_children.each(function (index, item) {
        if (t.current_pos == parseInt(Spdy(item).data('index'))) {
          Spdy(item).addClass('active');
        }
      });
    }
  },
};

function createCarouselWidget(options) {
  return new CarouselWidget(options);
}

module.exports = createCarouselWidget;
