const Spdy = require('./Speedy');

let FeatureDetailController = function (options) {
  let t = this;
  t.seasons_container = Spdy('.copy_box.series');
  t.seasons_enabled = false;
  if (t.seasons_container.elements.length > 0) {
    t.seasons_enabled = true;
    t.season_menu = t.seasons_container.find('.select_menu');
  }

  t.init();

  return t;
};

FeatureDetailController.prototype = {
  init: function () {
    let t = this;

    t.create();

    return t;
  },

  create: function () {
    let t = this;

    // SEASON MENU SELECTION //
    if (t.seasons_enabled) {
      t.season_select_buttons = t.season_menu.find('.select_menu [data-season]');
      t.season_select_buttons.on('click', function (e) {
        Spdy.preventDefault(e);
        t.onSeasonMenuClick(this);
      });
    }
    // SEASON MENU SELECTION //

    Spdy('.select_menu').on('click', function () {
      Spdy(this).toggleClass('active');
    });
  },

  onSeasonMenuClick: function (item) {
    let t = this;
    if (t.seasons_enabled) {
      t.seasons_container.find('[data-season]').removeClass('active');
      t.seasons_container
        .find('[data-season="' + Spdy(item).data('season') + '"]')
        .addClass('active');
      t.season_menu.find('.selected .vcenter2').text(Spdy(item).text());
    }
  },

  destroy: function () {
    let t = this;
    // SEASON MENU SELECTION //
    if (t.seasons_enabled) {
      t.season_select_buttons.off('click');
    }
    // SEASON MENU SELECTION //
  },
};

function createFeatureDetailController(options) {
  return new FeatureDetailController(options);
}

module.exports = createFeatureDetailController;
