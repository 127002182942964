var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<a target=\"blank\" class=\"feed_item\" href=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\">\n		"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"featured_image_url") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":126}}})) != null ? stack1 : "")
    + "<!--\n	 --><div class=\"text_area "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"featured_image_url") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":27},"end":{"line":5,"column":73}}})) != null ? stack1 : "")
    + "\">\n	 		<div class=\"feed_item_title\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"rendered") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n	 		<div class=\"feed_item_excerpt\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"excerpt") : depth0)) != null ? lookupProperty(stack1,"rendered") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n	 	</div>\n	</a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"image\" style=\"background-image:url('"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"featured_image_url") : depth0), depth0))
    + "');\"></div>";
},"4":function(container,depth0,helpers,partials,data) {
    return "fitimage";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"feed_wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"newsData") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":10,"column":10}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});