let SearchAutoCompleteController = function (options) {
  let t = this;
  t.dynamic_url = options.dynamic_url || '';
  t.static_url = options.static_url || '';
  t.locale = options.locale || '';
  t.data = options.data || [];

  t.template = options.template || HandlebarsTemplates['SearchResults'];
  t.inputField = options.inputField || Spdy('#mainnav_search');
  t.container = options.container || Spdy('.search_autocomplete');

  t.create();

  return t;
};

SearchAutoCompleteController.prototype = {
  create: function () {
    let t = this;
    /*t.inputField.on('change', function(){
                t.searchData();
            });*/
    t.inputField.on('keyup', function () {
      t.searchData();
    });
    t.hideResults = t.hideResults.bind(t);
    Spdy(window).on('resize', t.hideResults);
  },

  render: function () {
    let t = this;

    t.container.find('.autocomplete_result').off('click');

    t.container.html(
      t.template({
        locale: t.locale,
        staticUrl: t.static_url,
        dynamicUrl: t.dynamic_url,
        data: t.filteredData,
      })
    );
    if (t.filteredData.length > 0) {
      t.container.addClass('active');
    } else {
      t.container.removeClass('active');
    }

    t.container.find('.autocomplete_result').on('click', function (e) {
      Spdy.preventDefault(e);
      for (let i in t.data) {
        if (t.data[i].id == Spdy(this).data('id')) {
          t.inputField.elements[0].value = t.data[i].name;
          t.inputField.parent().submit();
        }
      }
    });
  },
  searchData: function () {
    let t = this;
    t.filteredData = [];
    let targetValue = t.inputField.elements[0].value;
    if (targetValue != '' && targetValue.length > 1) {
      let targetRegex = new RegExp(targetValue, 'i');
      for (let i in t.data) {
        if (t.data[i].name.search(targetRegex) != -1) {
          t.filteredData.push(t.data[i]);
        }
      }
    }
    t.render();
  },

  destroy: function () {
    let t = this;
    /*t.inputField.off('change', function(){
                t.searchData();
            });*/
    t.inputField.off('keyup', function () {
      t.searchData();
    });
    Spdy(window).off('resize', t.hideResults);
  },
  hideResults: function () {
    let t = this;
    t.container.removeClass('active');
  },
};

function createSearchAutoCompleteController(options) {
  return new SearchAutoCompleteController(options);
}

module.exports = createSearchAutoCompleteController;
