import { trackPageview } from 'modules/ensighten_tracking/page/ensightenPageview';
import { trackInteraction } from 'modules/ensighten_tracking/interaction/ensightenInteraction';
import { getLogOptions } from 'constants/logging';
import { trackingIsEnabled } from 'utils/onetrust';

const { enabled: log, clr: logClr, prep } = getLogOptions({ id: 'tracking' });

const ensightenTrackingSetup = () => {
  log && console.log(`${prep} ensightenTrackingSetup`, logClr);
  handleOrientation();

  // movies / series clicks
  // DISABLED FOR NOW AS NOT IN USER STORIES
  // if ($('.inline_left.feature_landing_target .column a').length > 0) {
  //   $('.inline_left.feature_landing_target .column a').each(function(index, item) {
  //     $(item).on('click', function() {
  //       const trackingname = $(this).data('trackingname') || 'nvs';
  //       triggerTracking('interaction', {
  //         interaction: `social ${trackingname} click`,
  //         interactionevent: 1,
  //       });
  //     });
  //   });
  // }

  // social
  if ($('.social').length > 0) {
    $('.social a').each(function (index, item) {
      $(item).on('click', function () {
        const trackingname = $(this).data('trackingname') || 'nvs';
        triggerTracking('interaction-social', {
          interaction: `social ${trackingname} click`,
          interactionevent: 1,
        });
      });
    });
  }
  if ($('.share_area .global_share').length > 0) {
    $('.share_area .global_share a').each(function (index, item) {
      $(item).on('click', function () {
        const trackingname = $(this).data('trackingname') || 'nvs';
        triggerTracking('interaction-social', {
          interaction: `social ${trackingname} click`,
          interactionevent: 1,
        });
      });
    });
  }

  // footer links
  if ($('footer .links').length > 0) {
    $('footer .links a').each(function (index, item) {
      $(item).on('click', function () {
        const trackingname = $(this).data('trackingname') || 'nvs';
        triggerTracking('interaction-legal', {
          interaction: trackingname,
          interactionevent: 1,
        });
      });
    });
  }

  triggerTracking('pageview');
};

const handleOrientation = () => {
  const device = TURNERENTS.device;
  const dataLayer = window.dataLayer;
  const orientation = screen.orientation ? screen.orientation.angle : window.orientation;
  dataLayer.presentationtemplate = device === 'smartphone' ? 'mobile web' : device;

  if (device !== 'desktop') {
    if (orientation === 0 || orientation === 180) {
      dataLayer.screenorientation = 'mobile|potrait';
    } else {
      dataLayer.screenorientation = 'mobile|landscape';
    }

    window.addEventListener('resize', () => {
      const orientation = screen.orientation ? screen.orientation.angle : window.orientation;
      if (orientation === 0 || orientation === 180) {
        dataLayer.screenorientation = 'mobile|potrait';
      } else {
        dataLayer.screenorientation = 'mobile|landscape';
      }
    });
  } else {
    dataLayer.screenorientation = 'desktop';
  }
};

const parseDataLayer = (type, data) => {
  const { pageviewevent, ...baseData } = window.dataLayer;
  const mergedData = { ...baseData, ...data };

  const analyticsPageData = TURNERENTS.analyticsPageData || {};
  const { isSpecials = false, pageTitle = '' } = analyticsPageData;
  if (isSpecials) {
    const page = pageTitle.toLocaleLowerCase();
    mergedData.section = 'specials';
    mergedData.subsection = 'specials|' + page;
  }

  switch (type) {
    case 'pageview':
      mergedData.pageviewevent = 1;
      break;
    case 'interaction':
      mergedData.subsection = `${baseData.section}|detail`;
      mergedData.contenttype = `${baseData.section} page`;
      break;
    case 'interaction-homepage':
      break;
    case 'interaction-video':
      break;
    case 'interaction-social':
      mergedData.contenttype = 'social click';
      break;
    case 'interaction-legal':
      mergedData.contenttype = 'legal click';
      break;
    case 'interaction-external-feature':
      mergedData.contenttype = 'section front';
      break;
  }
  // '' or null values become 'nvs'
  for (const key of Object.keys(mergedData)) {
    let val = mergedData[key];
    if (typeof val !== 'number' && typeof val !== 'object') {
      val = val.toLowerCase();
    }
    mergedData[key] = val === '' || val === null ? 'nvs' : val;
  }
  return mergedData;
};

const triggerTracking = (type, data = {}) => {
  if (!trackingIsEnabled()) {
    return;
  }
  const mergedData = parseDataLayer(type, data);
  log && console.log(`${prep} triggerTracking ${type}`, logClr, mergedData);
  switch (type) {
    case 'pageview':
      return trackPageview({ mergedData });
    case 'interaction':
    case 'interaction-video':
    case 'interaction-social':
    case 'interaction-legal':
    case 'interaction-homepage':
    case 'interaction-external-feature':
      return trackInteraction({ mergedData });
    default:
      break;
  }
};

export { ensightenTrackingSetup, triggerTracking };
