import YoutubePlayer from 'modules/video/YoutubePlayer';
import BitmovinPlayer from 'modules/video/BitmovinPlayer';
import { getLogOptions } from 'constants/logging';

let VideoInlineController = function (options) {
  this.videoPlayerTarget = '.inline_video';
  this.log = getLogOptions({ id: 'video' });
  this.init();
  return this;
};

VideoInlineController.prototype = {
  init: function () {
    return this;
  },

  youTubeApiReady: function () {
    if (this.youTubePlayer) {
      this.log.enabled &&
        console.log(`${this.log.prep} VideoInlineController.youTubeApiReady`, this.log.clr);
      this.youTubePlayer.setupPlayer();
    }
  },

  openVideo: function ({ type, id, tracking_name, useDomID = false }) {
    this.log.enabled &&
      console.log(`${this.log.prep} openVideo ${type} ${id} | useDomID ${useDomID}`, this.log.clr);
    const elemetShouldAnchor = !Spdy(this.videoPlayerTarget).hasClass('no_anchor');
    if (elemetShouldAnchor) {
      window.scrollTo(0, Spdy(this.videoPlayerTarget).offset().top);
    }

    const autoplay = window.TURNERENTS.video_autoplay;
    const youtubeID = useDomID ? `youtube-player-container-${id}` : `youtube-player-container`;
    const bitmovinID = useDomID ? `bitmovin-player-${id}` : `bitmovin-player`;
    switch (type) {
      case 'youtube':
        this.createYTVideo(id, tracking_name, autoplay, youtubeID, bitmovinID);
        break;
      case 'avs': {
        this.createAVSVideo(id, tracking_name, autoplay, youtubeID, bitmovinID);
        break;
      }
    }
  },

  createYTVideo: function (id, tracking_name, autoplay, youtubeID, bitmovinID) {
    $(`#${youtubeID}`).css('display', 'block');
    $(`#${bitmovinID}`).css('display', 'none');
    this.avsPlayer && this.avsPlayer.stopVideo();

    if (this.youTubePlayer) {
      this.youTubePlayer.playVideo({ id, tracking_name, autoplay });
    } else {
      this.log.enabled && console.log(`${this.log.prep} createYTVideo ${id}`, this.log.clr);
      this.youTubePlayer = new YoutubePlayer({
        id,
        tracking_name,
        domElementId: 'youtube-player',
        autoplay,
      });
    }
  },

  createAVSVideo: function (id, tracking_name, autoplay, youtubeID, bitmovinID) {
    $(`#${youtubeID}`).css('display', 'none');
    $(`#${bitmovinID}`).css('display', 'block');
    this.youTubePlayer && this.youTubePlayer.stopVideo();

    const { avsData } = window.TURNERENTS;
    avsData.video_id = id && id !== '' ? id : '';
    avsData.staticUrl = window.TURNERENTS.static_url;
    avsData.autoplay = autoplay;

    if (this.avsPlayer) {
      this.avsPlayer.playVideo({ avsData });
    } else {
      this.log.enabled && console.log(`${this.log.prep} createAVSVideo ${id}`, this.log.clr);
      this.avsPlayer = new BitmovinPlayer({ avsData, domElementId: bitmovinID });
    }
  },
};

function createVideoInlineController(options) {
  return new VideoInlineController(options);
}

module.exports = createVideoInlineController;
