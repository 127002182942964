var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "				<li><a class=\"theme_clr\" data-filter=\""
    + alias2(alias1(depth0, depth0))
    + "\" href=\"#\">"
    + alias2(alias1(depth0, depth0))
    + "</a></li>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#\" class=\"video_a\" data-inlinevideoid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"video_id") : depth0), depth0))
    + "\" data-videotype=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" data-videoname=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tracking_name") : depth0), depth0))
    + "\">\n 		<div class=\"inner\">\n			<div class=\"image\" style=\"background-image:url('"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dynamic_url") : depths[1]), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + "');\"><div class=\"play_overlay\"><img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"static_url") : depths[1]), depth0))
    + "site/images/icon_play_up.png\" alt=\"\" class=\"up\"><img src=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"static_url") : depths[1]), depth0))
    + "site/images/icon_play_over.png\" alt=\"\" class=\"over\"></div></div>\n			<div class=\"overlay theme_bg_img\">\n				<div class=\"title_text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</div>\n			</div>\n		</div>\n	</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"feature_header\">\n	<div class=\"select_menu theme_bg_clr\">\n		<div class=\"selected\"><div class=\"vcenter1\"><div class=\"vcenter2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"select_show") || (depth0 != null ? lookupProperty(depth0,"select_show") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"select_show","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":83}}}) : helper)))
    + "</div></div></div>\n			<div class=\"arrow\">\n				<div class=\"vcenter1\">\n				<div class=\"vcenter2\"><span class=\"icon icon_arrow_slim_down\"></span></div>\n			</div>\n		</div>\n		<div class=\"dropdown theme_border_clr\">\n			<ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"filterOptions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n	<div class=\"feature_title theme_clr\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"current_selected_title") || (depth0 != null ? lookupProperty(depth0,"current_selected_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_selected_title","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":64}}}) : helper)))
    + "</div>\n</div>\n\n\n<div class=\"videos_area\">\n	"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"renderData") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":1},"end":{"line":29,"column":14}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true,"useDepths":true});