/* global __WATCH__:false */

/**
 * Adjust logging here
 * Its broken into sections so it can be turned on/off per section
 */
const ua = window.navigator.userAgent;
const isIE =
  ua.match(/windows/i) &&
  (ua.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);

const CLR_GREY = !isIE ? 'background: grey; color: yellow' : '';
const CLR_BLUE = !isIE ? 'background: blue; color: white' : '';
const CLR_GREEN = !isIE ? 'background: green; color: white' : '';
const CLR_PINK = !isIE ? 'background: pink; color: black' : '';
const CLR_RED = !isIE ? 'background: red; color: white' : '';

const cc = !isIE ? '%c' : '';

const defaultLog = { enabled: true, clr: CLR_GREY, warn: CLR_RED, prep: `${cc} ++` };
const enabled = true; // __WATCH__; // set to false to disable all logging

// prettier-ignore
export const LOG = {
  enabled,
  main:           { enabled: true, clr: CLR_GREY,  warn: CLR_RED, prep: `${cc} ++ MAIN :` },
  bootstrap:      { enabled: true, clr: CLR_GREY,  warn: CLR_RED, prep: `${cc} ++ BOOT :` },
  app:            { enabled: true, clr: CLR_BLUE,  warn: CLR_RED, prep: `${cc} ++ APP :` },
  tracking:       { enabled: true, clr: CLR_PINK,  warn: CLR_RED, prep: `${cc} ++ TRACK :` },
  video:          { enabled: true, clr: CLR_BLUE, warn: CLR_RED, prep: `${cc} ++ VIDEO :` },
  bitmovinPlayer: { enabled: true, clr: CLR_GREEN, warn: CLR_RED, prep: `${cc} ++ BM :` },
  ytPlayer:       { enabled: true, clr: CLR_GREEN, warn: CLR_RED, prep: `${cc} ++ YT :` },
};

console.log(`${cc} ++ Logging :`, CLR_GREY, LOG);

export const getLogOptions = ({ id }) => {
  const log = LOG[id] || defaultLog;
  log.enabled = !LOG.enabled ? false : log.enabled;
  return log;
};
