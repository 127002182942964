let qwery = require('qwery');

let Spdy = function (selectors) {
  let t = this;
  Spdy.use_qwery = true;
  if (typeof qwery !== 'undefined') {
    //qwery loaded
    return new SpdyObj(qwery(selectors));
  } else {
    //qwery not loaded
    Spdy.use_qwery = false;
    if (typeof selectors === 'string') {
      //use native querySelectorAll if its a string
      return new SpdyObj(document.querySelectorAll(selectors));
    } else {
      if (selectors.length === undefined) {
        //if selectors isn't a string and length===undefined - must be a dom element
        return new SpdyObj([selectors]);
      } else {
        //if selectors isn't a string and length!==undefined then selectors is an array type
        return new SpdyObj([selectors]);
      }
    }
  }
};

Spdy.ready = function (fn) {
  if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', fn);
  } else if (document.readyState !== 'loading') {
    fn();
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState !== 'loading') {
        fn();
      }
    });
  }
};

Spdy.remove = function (el) {
  el.parentNode.removeChild(el);
};

Spdy.preventDefault = function (event) {
  event.preventDefault ? event.preventDefault() : (event.returnValue = false);
};

Spdy.scrollTop = function () {
  return (
    pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
  );
};
Spdy.scrollLeft = function () {
  return (
    pageXOffset ||
    (document.documentElement || document.body.parentNode || document.body).scrollLeft
  );
};

Spdy.window = {};
Spdy.window.width = function () {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};
Spdy.window.height = function () {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

let SpdyObj = function (elements) {
  let t = this;
  t.elements = elements;
  t.length = elements.length;
};

SpdyObj.prototype = {
  hide: function () {
    this.each(function (index, el) {
      el.style.display = 'none';
    });
    return this;
  },
  show: function () {
    this.each(function (index, el) {
      el.style.display = '';
    });
    return this;
  },
  addClass: function (className) {
    let t = this;
    this.each(function (index, el) {
      t._addClass(el, className);
    });
    return this;
  },
  _addClass: function (el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  },
  removeClass: function (className) {
    let t = this;
    this.each(function (index, el) {
      t._removeClass(el, className);
    });
    return this;
  },
  _removeClass: function (el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  },
  setClass: function (className) {
    this.each(function (index, el) {
      el.className = className;
    });
    return this;
  },
  hasClass: function (className) {
    let array = [];
    let t = this;
    this.each(function (index, el) {
      array.push(t._hasClass(el, className));
    });

    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  _hasClass: function (el, className) {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    }
  },

  toggleClass: function (className) {
    let result;
    let t = this;
    this.each(function (index, el) {
      if (t._hasClass(el, className)) {
        t._removeClass(el, className);
      } else {
        t._addClass(el, className);
      }
    });

    return this;
  },
  append: function (new_html) {
    this.each(function (index, el) {
      if (typeof new_html === 'string') {
        let div = document.createElement('div');
        div.innerHTML = new_html;
        let elements = div.childNodes;
        for (let i in elements) {
          if (elements[0] && (elements[0].tagName || elements[0].nodeName)) {
            el.appendChild(elements[0]);
          }
        }
      } else {
        el.appendChild(new_html);
      }
    });
  },
  prepend: function (new_html) {
    this.each(function (index, el) {
      if (typeof new_html === 'string') {
        let div = document.createElement('div');
        div.innerHTML = new_html;
        let elements = div.childNodes;
        for (let i in elements) {
          if (elements[0] && (elements[0].tagName || elements[0].nodeName)) {
            el.insertBefore(elements[0], el.firstChild);
          }
        }
      } else {
        el.insertBefore(new_html, el.firstChild);
      }
    });
  },
  appendTo: function (parent) {
    this.each(function (index, el) {
      parent.appendChild(el);
    });
  },
  prependTo: function (parent) {
    this.each(function (index, el) {
      parent.insertBefore(el, parent.firstChild);
    });
  },
  insertBefore: function (parent, target_el) {
    this.each(function (index, el) {
      target_el && target_el.parentNode.insertBefore(el, target_el);
    });
  },
  insertAfter: function (target_el) {
    this.each(function (index, el) {
      target_el && target_el.parentNode.insertBefore(el, target_el.nextSibling);
    });
  },

  clone: function (el) {
    let array = [];
    this.each(function (index, el) {
      array.push(el.cloneNode(true));
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  attr: function (atrib, val) {
    let array = [];
    this.each(function (index, el) {
      if (val === undefined) {
        array.push(el.getAttribute(atrib));
      } else {
        el.setAttribute(atrib, val);
      }
    });
    if (val === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },
  data: function (data_name, val) {
    let array = [];
    this.each(function (index, el) {
      if (val === undefined) {
        array.push(el.getAttribute('data-' + data_name));
      } else {
        el.setAttribute('data-' + data_name, val);
      }
    });
    if (val === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },

  html: function (content) {
    let array = [];
    this.each(function (index, el) {
      if (content === undefined) {
        array.push(el.innerHTML);
      } else {
        el.innerHTML = '' + content;
      }
    });
    if (content === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },
  text: function (content) {
    let array = [];
    this.each(function (index, el) {
      if (content === undefined) {
        if (el.text !== undefined) {
          array.push(el.text);
        } else if (el.textContent !== undefined) {
          array.push(el.textContent);
        } else {
          array.push(el.innerText);
        }
      } else {
        if (el.textContent !== undefined) {
          el.textContent = content;
        } else {
          el.innerText = content;
        }
      }
    });
    if (content === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },
  value: function (new_value) {
    let array = [];
    this.each(function (index, el) {
      if (new_value === undefined) {
        array.push(el.value);
      } else {
        el.value = new_value;
      }
    });
    if (new_value === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },
  offset: function () {
    let array = [];
    this.each(function (index, el) {
      let rect = el.getBoundingClientRect();
      let document_body = document.documentElement || document.body.parentNode || document.body;
      let scrollTop = pageYOffset || document_body.scrollTop;
      let scrollLeft = pageXOffset || document_body.scrollLeft;
      array.push({
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
      });
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  outerWidth: function () {
    let array = [];
    this.each(function (index, el) {
      array.push(el.offsetWidth);
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  outerHeight: function () {
    let array = [];
    this.each(function (index, el) {
      array.push(el.offsetHeight);
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  parent: function () {
    let array = [];
    this.each(function (index, el) {
      array.push(el.parentNode);
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  position: function () {
    let array = [];
    this.each(function (index, el) {
      array.push({ left: el.offsetLeft, top: el.offsetTop });
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  children: function (el) {
    let array = [];
    this.each(function (index, el) {
      let children = [];
      for (let i = el.children.length; i--; ) {
        // Skip comment nodes on IE8
        if (el.children[i].nodeType != 8) children.unshift(el.children[i]);
      }
      array.push(children);
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },
  each: function (fn) {
    for (let i = 0; i < this.elements.length; i++) {
      fn(i, this.elements[i]);
    }
  },

  on: function (eventName, handler) {
    this.each(function (index, el) {
      if (!el.eventArray) {
        el.eventArray = {};
      }
      if (!el.eventArray[eventName]) {
        el.eventArray[eventName] = [];
      }
      el.eventArray[eventName].push(handler);

      if (el.addEventListener) {
        el.addEventListener(eventName, handler, { passive: false });
      } else {
        el.attachEvent('on' + eventName, function () {
          handler.call(el, window.event);
        });
      }
    });

    if (this.elements.length > 1) {
      return this.elements;
    } else {
      return this.elements[0];
    }
  },

  off: function (eventName, handler) {
    this.each(function (index, el) {
      if (!eventName) {
        if (el.eventArray) {
          for (let e_name in el.eventArray) {
            for (let i in el.eventArray[e_name]) {
              if (el.removeEventListener) {
                el.removeEventListener(e_name, el.eventArray[e_name][i]);
              } else {
                el.detachEvent('on' + e_name, el.eventArray[e_name][i]);
              }
            }
          }
        }
      } else if (!handler) {
        if (el.eventArray) {
          for (let i in el.eventArray[eventName]) {
            if (el.removeEventListener) {
              el.removeEventListener(eventName, el.eventArray[eventName][i]);
            } else {
              el.detachEvent('on' + eventName, el.eventArray[eventName][i]);
            }
          }
        }
      } else {
        if (el.eventArray) {
          for (let i in el.eventArray[eventName]) {
            if (handler == el.eventArray[eventName][i]) {
              if (el.removeEventListener) {
                el.removeEventListener(eventName, el.eventArray[eventName][i]);
              } else {
                el.detachEvent('on' + eventName, el.eventArray[eventName][i]);
              }
            }
          }
        }
      }
    });

    if (this.elements.length > 1) {
      return this.elements;
    } else {
      return this.elements[0];
    }
  },

  find: function (selector) {
    let array = [];
    this.each(function (index, el) {
      let new_SpdyObj;
      if (Spdy.use_qwery) {
        new_SpdyObj = new SpdyObj(qwery(selector, el));
      } else {
        new_SpdyObj = new SpdyObj(el.querySelectorAll(selector));
      }
      array.push(new_SpdyObj);
    });
    if (array.length != 1) {
      return array;
    } else {
      return array[0];
    }
  },

  css: function (css_name, val) {
    let array = [];
    this.each(function (index, el) {
      if (val === undefined) {
        array.push(el.style[css_name]);
      } else {
        el.style[css_name] = val;
      }
    });
    if (val === undefined) {
      if (array.length != 1) {
        return array;
      } else {
        return array[0];
      }
    }
  },

  remove: function () {
    this.each(function (index, el) {
      try {
        Spdy(el).off();
      } catch (e) {
        console.log(e);
      }

      try {
        el.parentNode.removeChild(el);
      } catch (e) {
        console.log(e);
      }
    });
  },
};

global.Spdy = Spdy;
global.SpdyObj = SpdyObj;

module.exports = Spdy;
